import React from 'react'
import {useLocation} from 'react-router-dom'
import {Container, Row, Col, Card} from 'react-bootstrap'
import {Button, Modal, Form} from 'react-bootstrap'
import {useState} from 'react'

import img1 from '../../../../_metronic/assets/inspections/inspect1.jpeg'
import img2 from '../../../../_metronic/assets/inspections/inspect2.jpeg'
import img3 from '../../../../_metronic/assets/inspections/inspect3.jpeg'
import Footer from './Footer'

interface InspectorData {
  firstname: string
  lastname: string
  service_name: string
  phone_no: string
  pricing: string
  description: string
  certification_details: string
  insurance_details: string
  experience_years: number
  rating: number
  availability: string[]
}

const accordionData = [
  {
    cityName: 'New South Wales',
    years: [2022, 2023, 2024],
  },
  {
    cityName: 'Victoria',
    years: [2021, 2022, 2023],
  },
  {
    cityName: 'QueensLand',
    years: [2020, 2022, 2023, 2024],
  },
]

const InspectorDetailProfilePage: React.FC = () => {
  // const location = useLocation()
  // const queryParams = new URLSearchParams(location.search)

  const location = useLocation()
  const inspectorData = (location.state as InspectorData) || {}

  const [showModal, setShowModal] = useState(false)

  const handleShow = () => setShowModal(true)
  const handleClose = () => setShowModal(false)

  // const {
  //   firstname = '',
  //   lastname = '',
  //   service_name = '',
  //   phone_no = '',
  //   pricing = '',
  //   description = '',
  //   certification_details = '',
  //   insurance_details = '',
  //   experience_years = 0,
  //   rating = 0,
  //   availability = [],
  // } = inspectorData

  return (
    <>
      <section className='ezy__header7 light'>
        <Container className='position-relative'>
          <Row className='align-items-center justify-content-center'>
            <Col lg={5} xl={4} className='mb-4 mb-lg-0 text-center text-lg-start order-2'>
              <Card className='price-card badge-light-success'>
                <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
                  <p className='price-text-p text-dark'>Price starting at just</p>
                  <p className='price-text text-success '>${inspectorData.pricing}</p>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={7} className='ps-lg-4 pe-xl-5 text-center text-lg-start'>
              <h2 className='ezy__header7-heading mb-4'>{inspectorData.service_name}</h2>
              <div className=''>
                <p className='ezy__header7-sub-heading badge badge-success'>
                  {inspectorData.firstname} {inspectorData.lastname}
                </p>
                <br></br>
                <br></br>
                <p className=''>
                  <Button className='btn btn-dark' onClick={handleShow}>
                    Book Inspector
                  </Button>

                  <Modal show={showModal} onHide={handleClose} size='lg' centered>
                    <Modal.Header closeButton>
                      <Modal.Title>Search Address</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Group controlId='searchaddress' className='mb-3'>
                          <Form.Control type='text' placeholder='Search...' />
                        </Form.Group>
                      </Form>
                    </Modal.Body>
                  </Modal>
                </p>
              </div>

              <div className='card w-75 mt-3 '>
                <div className='card-body'>
                  <h5 className='card-title'>
                    <h3>About Us:</h3>
                  </h5>
                  <p className='card-text mt-3'>{inspectorData.description}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className='card  mt-3 '>
          <section className=' overflow-hidden' style={{marginLeft: '105px'}}>
            <h3>Gallery</h3>
            <Container className='py-5 py-lg-5 px-5'>
              <Row className='justify-content-left mb-4 mb-lg-5'>
                {/* Image Cards */}
                <Col xs={12} md={6} lg={3} className='d-flex justify-content-center mb-4'>
                  <a href='#_'>
                    <img
                      src={img1}
                      className='img-fluid rounded-3 shadow-sm transform transition-transform duration-500 hover:rotate-0 hover:translate-y-n12 hover:scale-150'
                      alt=' 1'
                    />
                  </a>
                </Col>
                <Col xs={12} md={6} lg={3} className='d-flex justify-content-center mb-4'>
                  <a href='#_'>
                    <img
                      src={img2}
                      className='img-fluid rounded-3 shadow-sm transform transition-transform duration-500 hover:rotate-0 hover:translate-y-n12 hover:scale-150'
                      alt=' 2'
                    />
                  </a>
                </Col>
                <Col xs={12} md={6} lg={3} className='d-flex justify-content-center mb-4'>
                  <a href='#_'>
                    <img
                      src={img3}
                      className='img-fluid rounded-3 shadow-sm transform transition-transform duration-500 hover:rotate-0 hover:translate-y-n12 hover:scale-150'
                      alt=' 3'
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </section>
        </div>

        <div className='card w-75 ' style={{margin: '0 auto', marginLeft: '100px'}}>
          <div className='card-body '>
            <h2 className='card-title mb-6'>Area Serviced</h2>
            <div className='accordion' id='accordionPanelsStayOpenExample'>
              {accordionData.map((item, index) => (
                <div className='accordion-item' key={index}>
                  <h2 className='accordion-header' id={`panelsStayOpen-heading-${index}`}>
                    <button
                      className='accordion-button'
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#panelsStayOpen-collapse-${index}`}
                      aria-expanded='true'
                      aria-controls={`panelsStayOpen-collapse-${index}`}
                    >
                      {item.cityName}
                    </button>
                  </h2>
                  <div
                    id={`panelsStayOpen-collapse-${index}`}
                    className='accordion-collapse collapse show'
                    aria-labelledby={`panelsStayOpen-heading-${index}`}
                  >
                    <div className='accordion-body'>
                      {item.years.map((year, yearIndex) => (
                        <span
                          key={yearIndex}
                          className='badge badge-success'
                          style={{marginRight: '5px'}}
                        >
                          {year}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      <div>
        <Footer></Footer>
      </div>
      <style>
        {`
          .ezy__header7 {
            --bs-body-color: #373572;
            --bs-body-bg: rgb(255, 255, 255);
            --ezy-theme-color: rgb(13, 110, 253);
            --ezy-theme-color-rgb: 13, 110, 253;
            --ezy-shadow: 0px 4px 44px rgba(159, 190, 218, 0.37);

            background-color: var(--bs-body-bg);
            overflow: hidden;
            padding: 30px 0;
            position: relative;
          }

          @media (min-width: 768px) {
            .ezy__header7 {
              padding: 50px 0;
            }
          }

          .ezy__header7-heading {
            font-weight: bold;
            font-size: 25px;
            line-height: 25px;
            color: var(--bs-body-color);
          }

          @media (min-width: 768px) {
            .ezy__header7-heading {
              font-size: 45px;
              line-height: 50px;
            }
          }

          .ezy__header7-sub-heading {
            color:#ffffff;
            font-size: 13px;
            line-height: 1.2;
          }

          .ezy__header7-img {
            border: 5px solid rgba(244, 247, 253, 0.1);
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
            border-radius: 10%;
          }


                 .price-card {
  height: 120px;
  width: 250px;
  background: #d4edda;
  border: none;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column; 
  align-items: center;
  justify-content: center;
}

.price-text {
  font-size: 35px;
  font-weight: bold;
  
}
        `}
      </style>
    </>
  )
}

export default InspectorDetailProfilePage
