import React, {useEffect, useState} from 'react'
import axios from 'axios'
import img1 from '../../../../_metronic/assets/1.webp'
import img2 from '../../../../_metronic/assets/2.jpeg'
import img3 from '../../../../_metronic/assets/3.jpeg'
import img4 from '../../../../_metronic/assets/4.webp'
import img5 from '../../../../_metronic/assets/5.jpeg'
import img6 from '../../../../_metronic/assets/6.jpeg'
import img7 from '../../../../_metronic/assets/House.webp'
import img8 from '../../../../_metronic/assets/abc.webp'
import img9 from '../../../../_metronic/assets/prop.jpeg'
import {useNavigate} from 'react-router-dom'
import {Modal, Button, Table} from 'react-bootstrap'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../../auth/core/_requests'
import {useAuth} from '../../auth'
import * as Yup from 'yup'


const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9]



const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '', 
  password: '',
}

const statesMapping: {[key: number]: string} = {
  1: 'New South Wales',
  2: 'Victoria',
  3: 'Queensland',
  4: 'Western Australia',
  5: 'South Australia',
  6: 'Tasmania',
  7: 'Australian Capital Territory',
  8: 'Northern Territory',
}

interface Inspector {
  inspector_id: number
  certification_details: string
  insurance_details: string
  experience_years: number
  rating: number
  is_disabled: boolean
  firstname: string
  lastname: string
  phone_no: string
  availability: string[]
  terms: boolean
  cancel_policy: boolean
  code_conduct: boolean
}

interface CardData {
  service_id: number
  service_name: string
  pricing: string
  description: string
  inspector: Inspector
  imageUrl?: string
}

interface Property {
  property_id: number
  address: string
  city: string
  state: string
  zipcode: string
  seller: {
    seller_id: number
    user_id: number
    firstname: string
    lastname: string
    phone_no: string
    is_disabled: boolean
  } | null
  buyer: any | null 
  requests: {
    request_id: number
    request_date: string
    status: boolean
    inspection_date: string | null
    requester_role: number
    assigned_date: string | null
    accept: boolean
  }[]
}

const Inspectors: React.FC = () => {
  const [cardsData, setCardsData] = useState<CardData[]>([])
  const [showModal, setShowModal] = useState(false)
  const [showModal2, setShowModal2] = useState(false)
  const [currentStep, setCurrentStep] = useState(1)
  const [refresh, setRefresh] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [errorField, setErrorField] = useState<'email' | 'phone_no' | null>(null)
  const [matchingUsers, setMatchingUsers] = useState<any[]>([])
  const [showCard, setShowCard] = useState(false)
  const [loginUserDetails, setLoginUserDetails] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [loginError, setLoginError] = useState('')
  const {saveAuth, setCurrentUser} = useAuth()


  const [showRequestModal, setShowRequestModal] = useState(false)
  const [properties, setProperties] = useState<Property[]>([]) 
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone_no: '',
    role: 0,
    state_id: 0,
  })

  const [propertyData, setPropertyData] = useState({
    address: '',
    city: '',
    state: '',
    zipcode: '',
  })

  const [isAgent, setIsAgent] = useState(false)
  const [agentData, setAgentData] = useState({
    firstname: '',
    lastname: '',
    email: '',
    contact: '',
  })

  const navigate = useNavigate()

  useEffect(() => {
    axios
      .get('https://api.inspection.payplatter.in/api/inspection-services')
      .then((response) => {
        const dataWithImages = response.data.map((item: CardData, index: number) => ({
          ...item,
          imageUrl: images[index % images.length],
        }))
        setCardsData(dataWithImages)
      })
      .catch((error) => {
        console.error('Error fetching data:', error)
      })
  }, [])

  useEffect(() => {
    const showModalFromStorage = localStorage.getItem('showModal2')
    if (showModalFromStorage === 'true') {
      setShowModal2(true)
      localStorage.removeItem('showModal2')
    }
  }, [])

  const handleOpenProfilePage = (card: CardData) => {
    const inspectorData = {
      firstname: card.inspector.firstname,
      lastname: card.inspector.lastname,
      service_name: card.service_name,
      phone_no: card.inspector.phone_no,
      pricing: card.pricing,
      description: card.description,
      certification_details: card.inspector.certification_details,
      insurance_details: card.inspector.insurance_details,
      experience_years: card.inspector.experience_years,
      rating: card.inspector.rating,
      availability: card.inspector.availability,
    }

    navigate('/website/inspector-detailprofile', {state: inspectorData})
  }

  const handleChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target

    setFormData((prevData) => ({
      ...prevData,
      [name]: name === 'role' ? Number(value) : value,
    }))

    setPropertyData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  useEffect(() => {
    const showModalFromStorage = localStorage.getItem('showModal')
    if (showModalFromStorage === 'true') {
      setShowModal(true)
      localStorage.removeItem('showModal')
    }
  }, [])

  const handleAgentChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = e.target
    setAgentData((prevData) => ({
      ...prevData,
      [name]: value,
    }))
  }

  const openModel = () => {
    setShowModal(true)
  }

  const handleClose = () => {
    setShowModal2(false)
  }

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1)
  }

  const handlePrevStep = () => {
    setCurrentStep(currentStep - 1)
  }

  const handleRequestModalClose = () => setShowRequestModal(false)

  const handleCardClick = async (user: any) => {
    setFormData({
      firstname: user.firstname,
      lastname: user.lastname,
      email: user.email,
      phone_no: user.phone_no,
      state_id: user.state_id,
      role: formData.role,
    })

    setLoginUserDetails(user)

    setShowCard(false)
    setCurrentStep(3)
  }


  // handlesubmit for creating new user 
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const formattedFormData = {
      ...formData,
      role: Number(formData.role),
      state_id: Number(formData.state_id),
    }

    try {
      // Check if user exists
      const response = await fetch('https://api.inspection.payplatter.in/api/users')
      const userData = await response.json()
      const matchingUsers = userData.data.filter(
        (user: any) => user.email === formData.email || user.phone_no === formData.phone_no
      )

      if (matchingUsers.length > 0) {
        setMatchingUsers(matchingUsers)
        setShowCard(true)
      } else {
        const response = await fetch('https://api.inspection.payplatter.in/api/users', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formattedFormData),
        })

        if (!response.ok) {
          const errorData = await response.json()
          console.error('Error registering the user:', errorData)
        } else {
          const data = await response.json()
          console.log('User registered successfully:', data)
          setRefresh(true)
          setFormData({
            firstname: '',
            lastname: '',
            email: '',
            phone_no: '',
            role: 0,
            state_id: 0,
          })
          localStorage.setItem('showModal2', 'true')
          // window.location.reload()
        }
      }
    } catch (error) {
      console.error('Error occurred:', error)
    }
  }







  const auth = useAuth()
  const currentUser = auth.currentUser
  const currentUserId = currentUser?.user_id
  // const currentUserId = 137;
  
  console.log(currentUserId)



  // function to submit propertyData 
  const PropertySubmitHandle = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    console.log(propertyData)
    console.log(agentData)
    

    try {
      // Submit property data
      await axios.post(`https://api.inspection.payplatter.in/api/properties`, propertyData)
      console.log(propertyData)
      console.log('Property submitted')

      // Submit agent data if applicable
      if (isAgent) {
        await axios.post(`https://api.inspection.payplatter.in/api/agents/${currentUserId}`, agentData)
        console.log(agentData)
        console.log('Agent submitted')
      }

      console.log('Property and agent details submitted successfully.')

      // Reload the page and open new modal
      // window.location.reload()
      setTimeout(() => {
        setShowRequestModal(true)
      }, 1000)
    } catch (error) {
      console.error('Error submitting property or agent details:', error)
    }
  }


  // Fetch properties when the component mounts
  useEffect(() => {
    fetchProperties()
  }, [])

  // API call to fetch properties
  const fetchProperties = async () => {
    try {
      const response = await axios.get(`https://api.inspection.payplatter.in/api/properties`)
      setProperties(response.data) // Update state with fetched data
    } catch (error) {
      console.error('Error fetching properties:', error)
    }
  }

  const handleAction = (propertyId: number) => {
    // Define what happens when the action button is clicked
    console.log('Requesting inspection for property:', propertyId)
    // Add more logic as needed
  }

  useEffect(() => {
    if (refresh) {
      window.location.reload()
      setRefresh(false)
    }
  }, [refresh])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)
        saveAuth(auth)
        console.log('user' +auth.user)
        setShowModal(false) // Close the modal on successful login
      } catch (error) {
        console.error(error)
        setLoginError('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <div className='container'>
      <div className='container'>
        <div className='row justify-content-center'>
          {cardsData.map((card) => (
            <div key={card.service_id} className='col-md-10 mb-4'>
              <div className='card' style={{maxWidth: '100%', margin: '0 auto'}}>
                <div className='row g-0'>
                  <div className='col-md-4'>
                    <img
                      src={card.imageUrl}
                      alt={card.service_name}
                      className='img-fluid rounded-start'
                      style={{height: '100%', objectFit: 'cover'}}
                    />
                  </div>
                  <div className='col-md-8'>
                    <div className='card-body'>
                      <h2 className='card-title'>{card.service_name}</h2>
                      <h3 className='card-title badge badge-success'>
                        {`${card.inspector.firstname} ${card.inspector.lastname}`}
                      </h3>
                      <p className='card-text'>{card.description}</p>

                      <div
                        className='d-flex justify-content-between align-items-center mt-3'
                        style={{marginTop: 'auto'}}
                      >
                        <span className='badge badge-light-success priceBadge'>
                          {`$${card.pricing}`}
                        </span>
                        <button
                          className='btn btn-primary'
                          onClick={openModel}
                          type='button'
                          style={{padding: '8px 12px'}}
                        >
                          Order Report
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Order Report Modal */}
      <div
        className={`modal fade ${showModal ? 'show' : ''}`}
        tabIndex={-1}
        aria-hidden='true'
        style={{
          display: showModal ? 'block' : 'none',
          backgroundColor: showModal ? 'rgba(0, 0, 0, 0.5)' : 'transparent',
        }}
      >
        <div className='modal-dialog modal-dialog-centered mw-600px'>
          <div
            className='modal-content'
            style={{borderRadius: '15px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'}}
          >
            <div
              className='modal-header'
              style={{borderBottom: '1px solid #dee2e6', backgroundColor: '#f8f9fa'}}
            >
              <h5 className='modal-title' style={{fontSize: '1.25rem', fontWeight: '500'}}>
                Register as Buyer or Seller
              </h5>
              <button
                type='button'
                className='btn btn-sm btn-icon btn-light'
                onClick={() => setShowModal(false)}
                aria-label='Close'
                style={{borderRadius: '50%'}}
              >
                <svg
                  className='svg-icon-1x'
                  aria-hidden='true'
                  style={{width: '1.5rem', height: '1.5rem'}}
                >
                  <path d='M10 10 L20 20 M20 10 L10 20' stroke='black' strokeWidth='2' />
                </svg>
              </button>
            </div>
            <div className='modal-body' style={{padding: '2rem'}}>
              <form className='form' onSubmit={handleSubmit}>
                {currentStep === 1 && (
                  <div>
                    <h3 style={{marginBottom: '1rem', fontSize: '1.2rem', fontWeight: '500'}}>
                      Select Profile
                    </h3>
                    <div style={{marginBottom: '1.5rem'}}>
                      <div className='fv-row mb-3'>
                        <label className='form-label' style={{fontSize: '1rem'}}>
                          Are you a Buyer or Seller?
                        </label>
                        <div className='d-flex justify-content-start'>
                          <div className='form-check me-4'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='role'
                              id='buyer'
                              value={3}
                              checked={formData.role === 3}
                              onChange={handleChange}
                              required
                            />
                            <label className='form-check-label' htmlFor='buyer'>
                              Buyer
                            </label>
                          </div>
                          <div className='form-check'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='role'
                              id='seller'
                              value={4}
                              checked={formData.role === 4}
                              onChange={handleChange}
                              required
                            />
                            <label className='form-check-label' htmlFor='seller'>
                              Seller
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      type='button'
                      className='btn btn-primary'
                      onClick={handleNextStep}
                      style={{borderRadius: '8px'}}
                    >
                      Next
                    </button>
                  </div>
                )}

                {currentStep === 2 && (
                  <div>
                    <h3 style={{marginBottom: '1rem', fontSize: '1.2rem', fontWeight: '500'}}>
                      Step 2: User Details
                    </h3>
                    <div style={{marginBottom: '1.5rem'}}>
                      <div className='fv-row mb-3' style={{display: 'flex', gap: '10px'}}>
                        <div className='form-floating mb-3' style={{flex: '1'}}>
                          <input
                            type='text'
                            className='form-control'
                            name='firstname'
                            placeholder='First Name'
                            value={formData.firstname}
                            onChange={handleChange}
                            required
                            pattern='^[A-Za-z]+$'
                            title='First name must contain only letters'
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          />
                          <label>First Name</label>
                        </div>
                        <div className='form-floating mb-3' style={{flex: '1'}}>
                          <input
                            type='text'
                            className='form-control'
                            name='lastname'
                            placeholder='Last Name'
                            value={formData.lastname}
                            onChange={handleChange}
                            required
                            pattern='^[A-Za-z]+$'
                            title='Last name must contain only letters'
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          />
                          <label>Last Name</label>
                        </div>
                      </div>
                      <div className='fv-row mb-3' style={{display: 'flex', gap: '10px'}}>
                        <div className='form-floating mb-3' style={{flex: '1'}}>
                          <input
                            type='email'
                            className='form-control'
                            name='email'
                            placeholder='Email'
                            value={formData.email}
                            onChange={handleChange}
                            required
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          />
                          <label>Email</label>
                          {errorMessage && errorField === 'email' && (
                            <div style={{color: 'red', fontSize: '0.875rem'}}>{errorMessage}</div>
                          )}
                        </div>
                        <div className='form-floating mb-3' style={{flex: '1'}}>
                          <input
                            type='tel'
                            className='form-control'
                            name='phone_no'
                            placeholder='Mobile Number'
                            value={formData.phone_no}
                            onChange={handleChange}
                            required
                            pattern='^[0-9]{10}$'
                            title='Mobile number must be 10 digits'
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          />
                          <label>Mobile Number</label>
                          {errorMessage && errorField === 'phone_no' && (
                            <div style={{color: 'red', fontSize: '0.875rem'}}>{errorMessage}</div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row mb-3'>
                        <div className='form-floating'>
                          <select
                            className='form-control'
                            name='state_id'
                            value={formData.state_id}
                            onChange={handleChange}
                            required
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          >
                            <option value=''>Select State</option>
                            {Object.entries(statesMapping).map(([key, value]) => (
                              <option key={key} value={key}>
                                {value}
                              </option>
                            ))}
                          </select>
                          <label>State</label>
                        </div>
                      </div>
                    </div>

                    <div>
                      {/* Existing Step 2 code */}
                      <div className='d-flex justify-content-between'>
                        <button
                          type='button'
                          className='btn btn-secondary'
                          onClick={handlePrevStep}
                          style={{borderRadius: '8px'}}
                        >
                          Back
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          style={{borderRadius: '8px'}}
                          disabled={showCard}
                        >
                          {showCard ? 'Next' : 'Submit'}
                        </button>
                      </div>
                    </div>

                    {showCard && (
                      <div className='alert alert-warning' role='alert' style={{marginTop: '1rem'}}>
                        {matchingUsers.map((user: any, index: number) => (
                          <div key={user.id} style={{marginBottom: '1rem'}}>
                            {index > 0 && <hr />}
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <div>
                                <p style={{margin: '0'}}>Account already exists.</p>
                                <p style={{margin: '0'}}>Email: {user.email}</p>
                                {/* <p style={{margin: '0'}}>Mobile: {user.phone_no}</p> */}
                              </div>
                              <button
                                className='btn btn-primary'
                                onClick={() => handleCardClick(user)}
                              >
                                Login
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </form>




              <form  onSubmit={formik.handleSubmit}>
                {currentStep === 3 && (
                  <div>
                    <h3
                      style={{
                        marginBottom: '1rem',
                        fontSize: '1.2rem',
                        fontWeight: '500',
                      }}
                    >
                      Login here
                    </h3>
                    <div style={{marginBottom: '1.5rem'}}>
                    <div className='fv-row mb-3'>
                        <div className='form-floating'>
                          <input
                            type='email'
                            className='form-control'
                            placeholder='Email'
                            // value={loginUserDetails?.email ||  {...formik.getFieldProps('email')}}
                            // readOnly
                            {...formik.getFieldProps('email')}
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          />
                          <label>Email</label>
                          {formik.touched.email && formik.errors.email && (
                            <div style={{color: 'red', fontSize: '0.875rem'}}>
                              {formik.errors.email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='fv-row mb-3'>
                        <div className='form-floating'>
                          <input
                            type='password'
                            className='form-control'
                            // name='password'
                            placeholder='Password'
                            {...formik.getFieldProps('password')}
                            style={{borderRadius: '8px', borderColor: '#ced4da'}}
                          />
                          <label>Password</label>
                          {formik.touched.password && formik.errors.password && (
                            <div style={{color: 'red', fontSize: '0.875rem'}}>
                              {formik.errors.password}
                            </div>

                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between'>
                      <button
                        type='button'
                        className='btn btn-secondary'
                        onClick={handlePrevStep}
                        style={{borderRadius: '8px'}}
                      >
                        Back
                      </button>
                      <button
                        type='submit'
                        className='btn btn-primary'
                        style={{borderRadius: '8px'}}
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {loading ? (
                          <span className='indicator-progress' style={{display: 'block'}}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        ) : (
                          'Submit'
                        )}
                      </button>
                    </div>
                    {loginError && (
                      <div className='alert alert-danger' role='alert' style={{marginTop: '1rem'}}>
                        {loginError}
                      </div>
                    )}
                  </div>
                )}
              </form>


            </div>
          </div>
        </div>
      </div>

      {/* property modal  */}

      <Modal
        show={showModal2}
        onHide={handleClose}
        size='lg'
        centered
        className='custom-modal-backdrop'
      >
        <Modal.Header closeButton>
          <Modal.Title>Property Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={PropertySubmitHandle}>
            <div>
              <h2 className='mb-4'>Property Details</h2>

              <div className='mb-3'>
                <label htmlFor='address' className='form-label'>
                  Address
                </label>
                <input
                  type='text'
                  className='form-control'
                  id='address'
                  name='address'
                  value={propertyData.address || ''}
                  onChange={handleChange}
                />
              </div>

              <div className='row mb-3'>
                <div className='col-md-6'>
                  <label htmlFor='city' className='form-label'>
                    City
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='city'
                    name='city'
                    value={propertyData.city || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-md-6'>
                  <label htmlFor='zipcode' className='form-label'>
                    Zipcode
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    id='zipcode'
                    name='zipcode'
                    value={propertyData.zipcode || ''}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='mb-3'>
                <label htmlFor='state' className='form-label'>
                  State
                </label>
                <select
                  className='form-select'
                  id='state'
                  name='state'
                  value={propertyData.state || ''}
                  onChange={handleChange}
                >
                  <option value=''>Select State</option>
                  {Object.entries(statesMapping).map(([key, value]) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className='mb-3 d-flex align-items-center '>
                <p className='mb-0 me-3 mt-3'>Do you have an different agent?</p>
                <input
                  type='checkbox'
                  className='form-check-input'
                  checked={isAgent}
                  onChange={() => setIsAgent(!isAgent)}
                />
              </div>
              {isAgent && (
                <div className='agent-details'>
                  <h2 className='mb-4'>Agent Details</h2>
                  <div className='row mb-3'>
                    <div className='col'>
                      <label className='form-label'>Agent First Name</label>
                      <input
                        type='text'
                        className='form-control'
                        name='firstname'
                        value={agentData.firstname || ''}
                        onChange={handleAgentChange}
                      />
                    </div>
                    <div className='col'>
                      <label className='form-label'>Agent Last Name</label>
                      <input
                        type='text'
                        className='form-control'
                        name='lastname'
                        value={agentData.lastname || ''}
                        onChange={handleAgentChange}
                      />
                    </div>
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Agent Email</label>
                    <input
                      type='email'
                      className='form-control'
                      name='email'
                      value={agentData.email || ''}
                      onChange={handleAgentChange}
                    />
                  </div>
                  <div className='mb-3'>
                    <label className='form-label'>Agent Phone Number</label>
                    <input
                      type='text'
                      className='form-control'
                      name='contact'
                      value={agentData.contact || ''}
                      onChange={handleAgentChange}
                    />
                  </div>
                </div>
              )}
            </div>
            <Modal.Footer>
              <Button variant='primary' type='submit'>
                Submit
              </Button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>

      {/* request Inspection modal  */}

      <Modal
        show={showRequestModal}
        onHide={handleRequestModalClose}
        size='lg'
        centered
        className='custom-modal-backdrop'
      >
        <Modal.Header closeButton>
          <Modal.Title>Request Property for Inspection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Zipcode</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {properties.map((property) => (
                <tr key={property.property_id}>
                  <td>{property.address}</td>
                  <td>{property.city}</td>
                  <td>{property.state}</td>
                  <td>{property.zipcode}</td>
                  <td>
                    <Button variant='primary' onClick={() => handleAction(property.property_id)}>
                      Request Inspection
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleRequestModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style>{`
        .container {
          padding: 20px;
        }
        .card {
          width: 100%;
          border: 1px solid #ddd;
          border-radius: 8px;
          overflow: hidden;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
        }
        .cardImage {
          padding: 15px;
        }
        .cardBody {
          flex: 1;
          padding: 15px;
          overflow: hidden;
          display: flex;
          flex-direction: column;
        }
        .cardTitle {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: bold;
        }
        .cardText {
          margin-bottom: 15px;
          font-size: 14px;
          color: #555;
          flex-grow: 1;
        }
        .priceBadge {
          height: 30px;
          width: 55px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }

        .custom-modal-backdrop {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
      `}</style>
    </div>
  )
}
export default Inspectors
