import axios, { AxiosResponse } from "axios"
import { AllRolesResponse, AllStatesResponse, AllUserResponse, CreateUser, PermissionResponse, RolePermissionsResponse , InspectorProfile, InspectionRequest, CreateProperty, CreateInspectionRequest, InspectionRequestOfInspector, AllEditUserResponse, CreateInspectionReport} from "./_models";

const API_URL = "https://api.inspection.payplatter.in/api"
// const API_URL = "http://[::1]:5000/api"
// const API_URL = "http://localhost:5000/api"
// const API_URL = "http://192.168.0.176:5000/api"

const GET_ALL_USERS = `${API_URL}/users`
const GET_ALL_ROLES = `${API_URL}/roles`
const GET_ALL_STATES = `${API_URL}/states`
const GET_ALL_USERS_BY_ROLE = `${API_URL}/users/role/`
const GET_INSPECTOR_PROFILE = `${API_URL}/inspectors/profile/`
const UPDATE_A_USER =`${API_URL}/users`
const REPORT= `${API_URL}/inspection-reports`

const createUser = (user: CreateUser): Promise<AllUserResponse> => {
    console.log(user)
    return axios
        .post(`${GET_ALL_USERS}`, user)
        .then((d: AxiosResponse<AllUserResponse>) => d.data)
}

const editUser = (id: any): Promise<AllEditUserResponse> => {

  return axios
      .put(`${GET_ALL_USERS}/${id}`,)
      .then((d: AxiosResponse<AllEditUserResponse>) => d.data)
}

const getRoles = (): Promise<AllRolesResponse> => {

    return axios
        .get(`${GET_ALL_ROLES}`,)
        .then((d: AxiosResponse<AllRolesResponse>) => d.data)
}
const getStates = (): Promise<AllStatesResponse> => {

  return axios
      .get(`${GET_ALL_STATES}`,)
      .then((d: AxiosResponse<AllStatesResponse>) => d.data)
}
const getUserList = (): Promise<AllUserResponse> => {

    return axios
        .get(`${GET_ALL_USERS}`,)
        .then((d: AxiosResponse<AllUserResponse>) => d.data)
}

const getUserListByRole = (id: any): Promise<AllUserResponse> => {

    return axios
        .get(`${GET_ALL_USERS_BY_ROLE + id}`,)
        .then((d: AxiosResponse<AllUserResponse>) => d.data)
}

const deleteUser = (id: any): Promise<AllUserResponse> => {

    return axios
        .delete(`${GET_ALL_USERS}/${id}`,)
        .then((d: AxiosResponse<AllUserResponse>) => d.data)
}

const deactivateUser = (id: any): Promise<AllUserResponse> => {

    return axios
        .get(`${GET_ALL_USERS}`,)
        .then((d: AxiosResponse<AllUserResponse>) => d.data)
}


const getInspectorProfile = (id: number): Promise<InspectorProfile> => {
  return axios
    .get(`${GET_INSPECTOR_PROFILE + id}`)
    .then((d: AxiosResponse<InspectorProfile>) => d.data);
};




export async function getRolesWithPermissions() {
    try {
      const response = await fetch('https://api.inspection.payplatter.in/api/roles/with-permissions');
      if (!response.ok) {
        throw new Error('Failed to fetch roles');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw error;
    }
  }

  const getRolePermissions = async (role_id: number): Promise<RolePermissionsResponse> => {
    const response = await axios.get(`${GET_ALL_ROLES}/${role_id}`);
    return response.data;
  };

  const getAllPermissions = (): Promise<PermissionResponse[]> => {
    return axios.get(`${API_URL}/permission`).then(response => response.data);
  };

  const removePermissionFromRole = (role_id: number, permission_id: number) => {
    return axios.delete(`${API_URL}/roles/${role_id}/permissions/${permission_id}`);
  };

  const addPermissionToRole = (role_id: number, permissions: { permissions: { permission_id: number }[] }) => {
    return axios.put(`${API_URL}/roles/${role_id}/permissions`, permissions);
  };

export { getUserList, deleteUser, deactivateUser, getRoles, getStates, createUser, getUserListByRole, getRolePermissions, removePermissionFromRole, addPermissionToRole, getAllPermissions, editUser ,getInspectorProfile};


//new

export const fetchInspectionRequests = async (): Promise<InspectionRequest[]> => {
  const response = await axios.get(`${API_URL}/inspection-requests`)
  return response.data
}

export const getInspectionRequestsByUser = async (userId: number): Promise<InspectionRequest[]> => {
  const response = await axios.get(`${API_URL}/inspection-requests/user/${userId}`);
  return response.data.data;
};

export const getInspectionRequestsOfInspector = async (user_id: number | undefined): Promise<InspectionRequestOfInspector[]> => {
  const response = await axios.get(`${API_URL}/inspection-requests/assigned-inspector/${user_id}`);
  return response.data;
};

export const createProperty = async (propertyData: CreateProperty, userRole: number, userId: number): Promise<any> => {
  const roleId = userRole === 3 ? { buyer_id: userId } : { seller_id: userId };
  const response = await axios.post(`${API_URL}/properties`, {
    ...propertyData,
    ...roleId,
  });
  return response.data;
};

export const createInspectionRequest = async (requestData: CreateInspectionRequest): Promise<any> => {
  const response = await axios.post(`${API_URL}/inspection-requests`, requestData);
  return response.data;
};

export const deleteInspectionRequest = async (requestId: number): Promise<any> => {
  const response = await axios.delete(`${API_URL}/inspection-requests/${requestId}`);
  return response.data;
};

// const createReport = (report: CreateUser): Promise<AllUserResponse> => {
//   console.log(user)
//   return axios
//       .post(`${GET_ALL_USERS}`, user)
//       .then((d: AxiosResponse<AllUserResponse>) => d.data)
// }

export const createInspectionReport = async (data: CreateInspectionReport) => {
  try {
    const response = await axios.post(`${REPORT}`, data);
    return response.data;
  } catch (error) {
    console.error("Error creating inspection report:", error);
    throw error;
  }
};