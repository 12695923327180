import {FC} from 'react'


const PaymentAndRefunds: FC = () => {
  return (
    <>
    Coming Soon
    </>
  )
}

export {PaymentAndRefunds}