import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'

import InspectorProfilePage from '../../modules/users/components/InspectorProfilePage'
import { InspectionsReports } from '../../modules/reports/components/InspectionsReports'

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Reports',
    path: 'reports',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const ReportsPage = () => (
  <Routes>
    <Route element={<Outlet />}>
      <Route
        path='inspections'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Inspections Reports</PageTitle>
            <InspectionsReports className='' />
          </>
        }
      />

      {/* <Route
        path='payment-refunds'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Payment Refunds</PageTitle>
            <PaymentAndRefunds />
          </>
        } /> */}
      <Route element={<Outlet />}>
        {/* <Route
  path='financial-reports'
  element={
    <>
      <PageTitle breadcrumbs={widgetsBreadCrumbs}>Financial Reports</PageTitle>
      <FinancialReports />
    </>
  } /> */}
      </Route>
      <Route
        path='profile/*'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>My Profile</PageTitle>
            <InspectorProfilePage />
          </>
        }
      />
    </Route>
  </Routes>
)

export default ReportsPage
