


import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { FinancialReports } from '../../modules/order-payment/components/FinancialReports'
import { ManageOrders } from '../../modules/order-payment/components/ManageOrders'
import { PaymentAndRefunds } from '../../modules/order-payment/components/PaymentAndRefunds'

import InspectorProfilePage from '../../modules/users/components/InspectorProfilePage'

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Order and Payments',
    path: 'orderandpayment',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OrderAndPaymentPage = () => (
  <Routes>
    <Route element={<Outlet />}>


      <Route
        path='manage-orders'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Manage orders</PageTitle>
            <ManageOrders />
          </>
        } />

      <Route
        path='payment-refunds'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Payment Refunds</PageTitle>
            <PaymentAndRefunds />
          </>
        } />
      <Route element={<Outlet />}>


        <Route
          path='financial-reports'
          element={
            <>
              <PageTitle breadcrumbs={widgetsBreadCrumbs}>Financial Reports</PageTitle>
              <FinancialReports />
            </>
          } />

      </Route>
      <Route
        path='profile/*'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>My Profile</PageTitle>
            <InspectorProfilePage />
          </>
        } />

    </Route>
  </Routes>
)

export default OrderAndPaymentPage
