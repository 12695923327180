


import { Navigate, Route, Routes, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { UserPage } from '../../modules/users/components/UserListPage'
import { InspectorPage } from '../../modules/users/components/InspectorPage'
import { RolePage } from '../../modules/users/components/RoleListPage'

import InspectorProfilePage from '../../modules/users/components/InspectorProfilePage'
import { EmployeePage } from '../../modules/users/components/EmployeePage'
import InspectorDetailsPage from '../../modules/users/components/InspectorDetailsPage'
import { RequestPage } from '../../modules/users/components/RequestListPage'

const widgetsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Inspections Request',
    path: 'requestinspections',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const RequestInspectionsPage = () => (
  <Routes>
    <Route element={<Outlet />}>


      <Route
        path='requestinspections'
        element={
          <>
            <PageTitle breadcrumbs={widgetsBreadCrumbs}>Inspection Request</PageTitle>
            <RequestPage />
          </>
        } />
      <Route element={<Outlet />}>


        

      </Route>
      

    </Route>
  </Routes>
)

export default RequestInspectionsPage
