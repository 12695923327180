
import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {Button, Col, Container, Row, Form, ListGroup, ListGroupItem, Spinner} from 'react-bootstrap'
import Navbar from './Navbar'
import demo2 from '../../../../_metronic/assets/2.jpeg'
import axios from 'axios'


const SearchSection: React.FC = () => {
  const [address, setAddress] = useState('')
  const [selectedSuggestion, setSelectedSuggestion] = useState<any | null>(null)
  const [loading, setLoading] = useState(false)
  const [suggestions, setSuggestions] = useState<any[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const searchInputRef = useRef<HTMLInputElement>(null)
  const navigate = useNavigate()
  const apiKey = 'AIzaSyAZULrIKjOnpT_Vz5TQxNGrBmb5TJF7Djk'
  const countryCode = 'AU'

  useEffect(() => {
    if (searchQuery) {
      // console.log('Fetching data for searchQuery:', searchQuery)

      axios
        .get('https://maps.googleapis.com/maps/api/place/autocomplete/json', {
          params: {
            input: searchQuery,
            key: apiKey,
            components: `country:${countryCode}`,
          },
        })
        .then((response) => {
          // console.log('API response:', response.data)
          if (response.data.predictions) {
            setSuggestions(response.data.predictions)
          } else {
            console.warn('No predictions found in API response:', response.data)
            setSuggestions([])
          }
        })
        .catch((error) => {
          console.error('Error fetching data from Google Places API:', error)
        })
    } else {
      setSuggestions([])
    }
  }, [searchQuery, apiKey])



  // useEffect(() => {
  //   if (searchQuery) {
  //     console.log('Fetching data for searchQuery:', searchQuery)
   
  //     axios
  //       .get('http://api.geonames.org/searchJSON', {
  //         params: {
  //           q: searchQuery,
  //           country: countryCode, 
  //           maxRows: 10, 
  //           username: 'harshhh', 
  //         },
  //       })
  //       .then((response) => {
  //         console.log('API response:', response.data)
  //         if (response.data.geonames) {
  //           setSuggestions(response.data.geonames)
  //         } else {
  //           console.warn('No results found in API response:', response.data)
  //           setSuggestions([])
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error fetching data from GeoNames API:', error)
  //       })
  //   } else {
  //     setSuggestions([])
  //   }
  // }, [searchQuery, countryCode])
  
  const handleSelect = (address: string) => {
    setAddress(address);
  };

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    if (selectedSuggestion) {
      setLoading(true)
      setTimeout(() => {
        navigate('/website/property-details', {state: selectedSuggestion})
        console.log(selectedSuggestion)
        setLoading(false)
      }, 1000)
    } else {
      console.error('No suggestion selected');
    }
  };

  return (
    <>
      <section
        className=''
        style={{
          backgroundImage: `url(${demo2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          color: '#fff',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '120vh',
          border: 'none',
          paddingBottom: '2%',
        }}
      >
        <div style={{position: 'absolute', top: 0, width: '100%', zIndex: 10}}>
          <Navbar />
        </div>

        <Container className="text-center text-white mt-10">
          <Row className="justify-content-center">
            <Col lg={7}>
              <h1
                className='opacity-100'
                style={{color: '#09A66A', fontSize: '60px', fontWeight: 'bolder'}}
              >
                Your Trusted Inspection Partner
              </h1>
              <h5 className='text-white' style={{color: '#00BFFF'}}>
                Ensuring your property's safety and compliance with meticulous inspections carried
                out by experienced professionals.
              </h5>
              <Form
                className='d-flex flex-column align-items-right position-relative mt-7'
                onSubmit={handleSearch}
              >
                <Row className='w-100 search justify-content-center'>
                  <Col md={10} className='p-0 position-relative '>
                    <Form.Control
                      type='text'
                      className='me-2 search-bar rounded-left'
                      style={{
                        height: '50px',
                        borderRadius: '50px',
                        fontWeight: 'bold',
                        color: '#808080',
                      }}
                      placeholder='Search for a property'
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value)
                        setSearchQuery(e.target.value)
                      }}
                      ref={searchInputRef}
                    />

{/* <ReactAddressAutoComplete
          useGoogle={true}
          googleKey=''
          inputProps={{
            id: 'location-search',
            name: 'location',
            value: address, 
            onChange: (e: React.ChangeEvent<HTMLInputElement>) => { 
              console.log('Input changed:', e.target.value);
              setAddress(e.target.value); 
              setSearchQuery(e.target.value);
            },
            placeholder: 'Enter location...',
          }}
        /> */}
                    <Button
                      variant='primary'
                      type='submit'
                      className='position-absolute top-0 end-0'
                      style={{
                        borderStartStartRadius: '0px',
                        borderEndStartRadius: '0px',
                        height: '50px',
                        borderRadius: '50px',
                        padding: '0 15px',
                        backgroundColor: '#008471',
                      }}
                    >
                      {loading ? <Spinner animation='border' size='sm' /> : 'Search'}
                    </Button>
                    {/* Suggestions list */}
                    <div
                      className='suggestion-list position-absolute mt-1'
                      style={{
                        width: searchInputRef.current?.offsetWidth || 'auto',
                        left: 0,
                        maxHeight: '500px',
                        overflowY: 'auto',
                        zIndex: 20,
                        backgroundColor: '#ffffff',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        borderRadius: '5px',
                      }}
                    >
                      <ListGroup>
                        {suggestions.map((suggestion, index) => (
                          <ListGroupItem
                            key={index}
                            action
                            className='suggestion-item'
                            style={{
                              padding: '16px',
                              fontWeight: 'bold',
                              color: '#000',
                              cursor: 'pointer',
                              borderBottom: '1px solid #e9e9e9',
                            }}
                            onClick={() => {
                              setAddress(suggestion.description)
                              setSelectedSuggestion(suggestion)
                              setSuggestions([])
                            }}
                          >
                            {suggestion.description}
                          </ListGroupItem>
                        ))}
                      </ListGroup>
                    </div>
                  </Col>
                </Row>
              </Form>

              {/* <Demo></Demo> */}

              <p className='mt-5 font-weight-bold'>
                Trouble in finding Property?
                <button
                  className='btn btn-transparent'
                  style={{
                    color: '#ffffff',
                  }}
                  type='button'
                  data-bs-toggle='offcanvas'
                  data-bs-target='#offcanvasRight'
                  aria-controls='offcanvasRight'
                >
                  Connect with us
                </button>
                <div
                  className='offcanvas offcanvas-end rounded'
                  tabIndex={-1}
                  id='offcanvasRight'
                  aria-labelledby='offcanvasRightLabel'
                >
                  <div className='offcanvas-header rounded'>
                    <h5 id='offcanvasRightLabel'>Connect with us !!</h5>
                    <button
                      type='button'
                      className='btn-close text-reset'
                      data-bs-dismiss='offcanvas'
                      aria-label='Close'
                    />
                  </div>
                  <div className='offcanvas-body'>
                    <form>
                      <div className='form-row mb-3'>
                        <div className='col-md-12'>
                          <input
                            type='text'
                            className='form-control'
                            id='validationDefault02'
                            placeholder='Name'
                            required
                            style={{color: 'black', width: '100%'}}
                          />
                        </div>
                      </div>

                      <div className='form-row mb-3'>
                        <div className='col-md-12'>
                          <input
                            type='email'
                            className='form-control'
                            id='validationDefault02'
                            placeholder='Email'
                            required
                            style={{color: 'black', width: '100%'}}
                          />
                        </div>
                      </div>

                      <div className='form-row mb-3'>
                        <div className='col-md-12'>
                          <input
                            type='tel'
                            className='form-control'
                            id='validationDefault04'
                            placeholder='Contact'
                            required
                            style={{color: 'black', width: '100%'}}
                          />
                        </div>
                      </div>

                      <div className='form-row mb-3'>
                        <div className='col-md-12'>
                          <input
                            type='text'
                            className='form-control'
                            id='validationDefault01'
                            placeholder='Property Address'
                            required
                            style={{color: 'black', width: '100%'}}
                          />
                        </div>
                      </div>
                      <div className='form-row mb-3'>
                        <div className='col-md-12'>
                          <textarea
                            className='form-control'
                            id='validationDefault01'
                            placeholder='Message'
                            required
                            style={{color: 'black', width: '100%'}}
                          />
                        </div>
                      </div>

                      <button className='btn btn-primary w-30 mb-5' type='submit'>
                        Submit
                      </button>
                    </form>

                    <div className='d-flex justify-content-center'>
                      <a
                        href='https://facebook.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <img
                          src='https://img.icons8.com/fluent/30/000000/facebook-new.png'
                          alt='Facebook'
                        />
                      </a>
                      <a
                        href='https://instagram.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <img
                          src='https://img.icons8.com/fluent/30/000000/instagram-new.png'
                          alt='Instagram'
                        />
                      </a>
                      <a
                        href='https://linkedin.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <img
                          src='https://img.icons8.com/fluent/30/000000/linkedin.png'
                          alt='LinkedIn'
                        />
                      </a>
                      <a
                        href='https://twitter.com'
                        target='_blank'
                        rel='noopener noreferrer'
                        className='mx-3'
                      >
                        <img
                          src='https://img.icons8.com/fluent/30/000000/twitter.png'
                          alt='Twitter'
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default SearchSection
