import React, { useState, useEffect, useRef } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../accounts/components/settings/SettingsModel'
import * as Yup from 'yup'
import { Formik, useFormik } from 'formik'
import { useAuth } from '../../auth'
import Select, { SingleValue, MultiValue, ActionMeta } from 'react-select'

import PdfModal from './PdfModal'

import { InspectorProfile, InspectorUpdate } from '../core/_models'
import { InspectorProfilePhoto } from '../core/_models'
import axios, { AxiosRequestConfig, Method } from 'axios'
import SignatureCanvas from 'react-signature-canvas'
import 'add-to-calendar-button'

import blankprofilepicture from '../../../../_metronic/assets/abc.webp'
import blankprofilepic from '../../../../_metronic/assets/blankprofilepic.jpg'

console.log("Hi", blankprofilepicture);

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string(),
  lName: Yup.string(),
  company: Yup.string(),
  contactPhone: Yup.string(),
  companySite: Yup.string(),
  country: Yup.string(),
  language: Yup.string(),
  timeZone: Yup.string(),
  currency: Yup.string(),
})


interface Option {
  value: string
  label: string
}

interface id_prop {
  userid: number;
}





const profileSchema = Yup.object().shape({
  profilePhoto: Yup.string(),
  fname: Yup.string(),
  lName: Yup.string(),
  contactPhone: Yup.string(),
  experience: Yup.number(),
  certificationDetails: Yup.string(),
  insuranceDetails: Yup.number()
})
const initialValue = {
  profilePhoto: '',
  fname: '',
  lName: '',
  contactPhone: '',
  experience: 0,
  certificationDetails: '',
  insuranceDetails: 0
}


const ProfileOverview: React.FC = (user_id) => {
  // Mapping the availability values to their corresponding labels
  const mapAvailabilityToLabels = (availability: string[], postalcodeList: { value: string; label: string }[]) => {
    return postalcodeList.filter(option => availability.includes(option.value));
  };

  const [data, setData] = useState<IProfileDetails>(initialValues)

  const [usersResponse, setUsersResponse] = useState<InspectorProfile | null>(null)
  // const [availability, setAvailability] = useState<Option[]>([])
  const [availabilities, setAvailabilities] = useState<string[]>([]);


  const [modalTitle, setModalTitle] = useState('')
  const [pdfUrl, setPdfUrl] = useState('')
  // const [profilePic, setProfilePic] = useState<InspectorProfilePhoto>({file: null})
  const [profilePic, setProfilePic] = useState<string>('')

  const [signatureSrc, setSignatureSrc] = useState<string>('')
  const sigCanvas = useRef<SignatureCanvas>(null)

  const [imageSrc, setImageSrc] = useState<string | null>(null)
  const [imageFile, setImageFile] = useState<File | null>(null)


  const auth = useAuth()
  const [loading, setLoading] = useState(false)
  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })

  const currentUser = auth.currentUser
  // console.log(currentUser)
  const currenteUserId = currentUser?.user_id
  // console.log(currenteUserId)
  const currenteUserRole = currentUser?.role
  const currenteUserPhoneNum = currentUser?.phone_no
  // console.log(currenteUserId)

  const clearSignature = () => {
    sigCanvas.current?.clear()
  }

  const saveSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL()
      const link = document.createElement('a')
      link.href = dataUrl
      link.download = 'signature.png'
      link.click()
    }
  }
  const uploadSignature = () => {
    if (sigCanvas.current) {
      const dataUrl = sigCanvas.current.toDataURL()
      const blob = dataURLToBlob(dataUrl)

      const formData = new FormData()
      formData.append('fileType', 'signature')
      formData.append('role', currenteUserRole?.toString() || '')
      formData.append('user_id', currenteUserId?.toString() || '')
      formData.append('phone_no', currenteUserPhoneNum?.toString() || '')
      formData.append('file', blob, 'signature.png')
      formData.append('description', 'signature image')

      const config: AxiosRequestConfig<FormData> = {
        method: 'post' as Method,
        maxBodyLength: Infinity,
        url: 'https://api.inspection.payplatter.in/api/file-upload',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data))
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  const postalcodeList = [
    { value: '2000', label: 'Sydney, NSW' },
    { value: '2001', label: 'Haymarket, NSW' },
    { value: '2002', label: 'World Square, NSW' },
    { value: '2006', label: 'The University of Sydney, NSW' },
    { value: '2007', label: 'Ultimo, NSW' },
    { value: '2008', label: 'Chippendale, NSW' },
    { value: '2009', label: 'Pyrmont, NSW' },
    { value: '2010', label: 'Surry Hills, NSW' },
    { value: '2011', label: 'Potts Point, NSW' },
    { value: '2015', label: 'Alexandria, NSW' },
    { value: '2016', label: 'Redfern, NSW' },
    { value: '2017', label: 'Waterloo, NSW' },
    { value: '2018', label: 'Eastgardens, NSW' },
    { value: '2020', label: 'Mascot, NSW' },
    { value: '2021', label: 'Paddington, NSW' },
    { value: '2022', label: 'Bondi Junction, NSW' },
    { value: '2023', label: 'Bellevue Hill, NSW' },
    { value: '2024', label: 'Woollahra, NSW' },
    { value: '2025', label: 'Woollahra, NSW' },
    { value: '2026', label: 'Bondi, NSW' },
    { value: '2027', label: 'Darling Point, NSW' },
    { value: '2028', label: 'Double Bay, NSW' },
    { value: '2029', label: 'Rose Bay, NSW' },
    { value: '2030', label: 'Vaucluse, NSW' },
    { value: '2031', label: 'Randwick, NSW' },
    { value: '2032', label: 'Kensington, NSW' },
    { value: '2033', label: 'Kingsford, NSW' },
    { value: '2034', label: 'Coogee, NSW' },
    { value: '2035', label: 'Maroubra, NSW' },
    { value: '2036', label: 'Matraville, NSW' },
    { value: '2037', label: 'Glebe, NSW' },
    { value: '2038', label: 'Annandale, NSW' },
    { value: '2039', label: 'Rozelle, NSW' },
    { value: '2040', label: 'Leichhardt, NSW' },
    { value: '2041', label: 'Balmain, NSW' },
    { value: '2042', label: 'Newtown, NSW' },
    { value: '2043', label: 'Erskineville, NSW' },
    { value: '2044', label: 'St Peters, NSW' },
    { value: '2045', label: 'Haberfield, NSW' },
    { value: '2046', label: 'Five Dock, NSW' },
    { value: '2047', label: 'Drummoyne, NSW' },
    { value: '2048', label: 'Stanmore, NSW' },
    { value: '2049', label: 'Petersham, NSW' },
    { value: '2050', label: 'Camperdown, NSW' },
    { value: '2052', label: 'University of New South Wales, NSW' },
    { value: '2060', label: 'North Sydney, NSW' },
    { value: '2061', label: 'Milsons Point, NSW' },
    { value: '2062', label: 'Cammeray, NSW' },
    { value: '2063', label: 'Northbridge, NSW' },
    { value: '2064', label: 'Artarmon, NSW' },
    { value: '2065', label: 'St Leonards, NSW' },
    { value: '2066', label: 'Lane Cove, NSW' },
    { value: '2067', label: 'Chatswood, NSW' },
    { value: '2068', label: 'Castlecrag, NSW' },
    { value: '2069', label: 'Roseville, NSW' },
    { value: '2070', label: 'Lindfield, NSW' },
    { value: '2071', label: 'Killara, NSW' },
    { value: '2072', label: 'Gordon, NSW' },
    { value: '2073', label: 'Pymble, NSW' },
    { value: '2074', label: 'Turramurra, NSW' },
    { value: '2075', label: 'St Ives, NSW' },
    { value: '2076', label: 'Hornsby, NSW' },
    { value: '2077', label: 'Asquith, NSW' },
    { value: '2079', label: 'Mount Colah, NSW' },
    { value: '2080', label: 'Mount Kuring-gai, NSW' },
    { value: '2081', label: 'Berowra, NSW' },
    { value: '2082', label: 'Cowan, NSW' },
    { value: '2083', label: 'Brooklyn, NSW' },
    { value: '2084', label: 'Terrey Hills, NSW' },
    { value: '2085', label: 'Belrose, NSW' },
    { value: '2086', label: 'Davidson, NSW' },
    { value: '2087', label: 'Forestville, NSW' },
    { value: '2088', label: 'Mosman, NSW' },
    { value: '2089', label: 'Kirribilli, NSW' },
    { value: '2090', label: 'Neutral Bay, NSW' },
    { value: '2092', label: 'Seaforth, NSW' },
    { value: '2093', label: 'Balgowlah, NSW' },
    { value: '2094', label: 'Fairlight, NSW' },
    { value: '2095', label: 'Manly, NSW' },
    { value: '2096', label: 'Queenscliff, NSW' },
    { value: '2097', label: 'Collaroy, NSW' },
    { value: '2099', label: 'Dee Why, NSW' },
  ]

  const handlecceptance = (title: string, accepted: boolean) => {
    setFormData({ ...formData, [title]: accepted })
    handleCloseModal()
  }

  const [showModal, setShowModal] = useState<boolean>(false)

  const handleOpenModal = (title: string, url: string) => {
    setModalTitle(title)
    setPdfUrl(url)
    setShowModal(true)
  }

  const handleCloseModal = () => setShowModal(false)

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     try {
  //       const response = await axios.get<InspectorProfile>(
  //         `http://[::1]:5000/api/inspectors/profile/${currenteUserId}`
  //       )
  //       console.log(response)
  //       setUsersResponse(response.data)
  //       // setAvailability(
  //       //   response.data.availability.map(
  //       //     (code) =>
  //       //       postalcodeList.find((item) => item.value === code) || { value: code, label: code }
  //       //   )
  //       // )
  //       setavailabilities(
  //         response.data.availability.map(
  //           (code) =>
  //             postalcodeList.find((item) => item.value === code) || { value: code, label: code }
  //         )
  //       )
  //       setLoading(false)
  //     } catch (err) {
  //       setLoading(false)
  //     }
  //   }

  //   fetchProfile()
  // }, [currenteUserId])

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await axios.get<InspectorProfile>(
          `https://api.inspection.payplatter.in/api/inspectors/profile/${currenteUserId}`
        );
        // console.log(response);

        setUsersResponse(response.data);

        // Map availability to the correct structure
        const mappedAvailabilities = response.data.availability.map((code) => {
          const foundOption = postalcodeList.find((item) => item.value === code);
          return foundOption ? foundOption.value : code;
        });

        setAvailabilities(mappedAvailabilities);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error('Error fetching profile:', err);
      }
    };

    fetchProfile();
  }, [currenteUserId]);


  const handleChange = (selectedOptions: MultiValue<Option>, actionMeta: ActionMeta<Option>) => {
    const selectedValues = selectedOptions.map(option => option.value);
    setAvailabilities(selectedValues);
  };
  // console.log("availabilities : ", availabilities);


  const fetchProfilePhoto = async () => {
    try {
      const response = await axios.get<InspectorProfilePhoto>(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/profilepic`
      )
      setProfilePic(
        `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/profilepic`
      )
      setLoading(false)
    } catch (err) {
      console.error('Error fetching Profilepic:', err)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchProfilePhoto()
  }, [])

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      setImageFile(file)
      const reader = new FileReader()
      reader.onloadend = () => {
        setImageSrc(reader.result as string)
      }
      reader.readAsDataURL(file)
      fetchProfilePhoto()
    }
  }
  fetchProfilePhoto()


  const uploadProfile = () => {
    if (!imageFile) return

    // console.log(imageFile)
    const formData2 = new FormData()
    formData2.append('fileType', 'profilepic')
    formData2.append('user_id', currenteUserId?.toString() || '')
    formData2.append('role', currenteUserRole?.toString() || '')
    formData2.append('phone_no', currenteUserPhoneNum?.toString() || '')
    formData2.append('file', imageFile)

    const config: AxiosRequestConfig<FormData> = {
      method: 'post' as Method,
      maxBodyLength: Infinity,
      url: 'https://api.inspection.payplatter.in/api/file-upload',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData2,
    }

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data))

      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    if (imageFile) {
      uploadProfile()
      fetchProfilePhoto()
    }
  }, [imageFile])

  useEffect(() => {
    const fetchSignature = async () => {
      try {
        const response = await axios.get<InspectorProfilePhoto>(
          `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/signature`
        )
        // console.log(response.data) // Verify the response structure
        setSignatureSrc(
          `https://api.inspection.payplatter.in/api/file-upload/${currenteUserRole}/${currenteUserId}/${currenteUserPhoneNum}/signature`
        )
        setLoading(false)
      } catch (err) {
        console.error('Error fetching signature:', err)
        setLoading(false)
      }
    }
    fetchSignature()
  }, [])

  const [formData, setFormData] = useState<InspectorUpdate>({
    firstname: '',
    lastname: '',
    phone_no: '',
    availability: [],
    terms: true,
    cancel_policy: true,
    code_conduct: true,
    certification_details: '',
    insurance_details: '',
    experience_years: 0,
  })

  const updatedData = {
    ...formData,
    availability: availabilities, 
  };


  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const updatedData = {
      ...formData,
      availability: availabilities, 
    };



    try {
      const response = await axios.put(
        `https://api.inspection.payplatter.in/api/inspectors/profile/${currenteUserId}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Data submitted successfully:', response.data);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error('Error submitting data:', error.response?.data || error.message);
      } else {
        console.error('Unexpected error:', error);
      }
    }
  };

  const handleChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type } = e.target

    // Handle number fields correctly
    const newValue = type === 'number' ? Number(value) : value

    setUsersResponse((prevState) => {
      if (prevState === null) {
        return {
          [name]: newValue,
        } as unknown as InspectorProfile
      }

      return {
        ...prevState,
        [name]: newValue,
      }
    })
  }

  const handleCheckboxChange = (isChecked: boolean) => {
    console.log("Checkbox Value:", isChecked);
  };


  type OptionType = { value: string; label: string };

  return (
    <div className='card mb-5 mb-xl-10'>

      <style>{` .fixed-size-image {  width: 200px;  height: 200px;  object-fit: cover;  }`}</style>

      <div className='card-header border-0 cursor-pointer' role='button' data-bs-toggle='collapse' data-bs-target='#kt_account_profile_details' aria-expanded='true' aria-controls='kt_account_profile_details'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>


        <Formik
          initialValues={{
            firstname: usersResponse?.firstname || '',
            lastname: usersResponse?.lastname || '',
            phone_no: usersResponse?.phone_no || '',
            experience_years: usersResponse?.experience_years || '',
            certification_details: usersResponse?.certification_details || '',
            insurance_details: usersResponse?.insurance_details || '',
            availability: mapAvailabilityToLabels(usersResponse?.availability || [], postalcodeList),
          }}
          validationSchema={profileSchema}
          onSubmit={async (values) => {
            const updatedValues = {
              ...values,
              firstname: values.firstname || usersResponse?.firstname,
              lastname: values.lastname || usersResponse?.lastname,
              phone_no: values.phone_no || usersResponse?.phone_no,
              experience_years: values.experience_years || usersResponse?.experience_years,
              certification_details: values.certification_details || usersResponse?.certification_details,
              insurance_details: values.insurance_details || usersResponse?.insurance_details,
              availability: availabilities, 
            };


            try {
              const response = await axios.put(
                `https://api.inspection.payplatter.in/api/inspectors/profile/${currenteUserId}`,
                updatedValues,
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }
              );
              console.log(response);
            } catch (error) {
              if (axios.isAxiosError(error)) {
                console.error('Error submitting data:', error.response?.data || error.message);
              } else {
                console.error('Unexpected error:', error);
              }
            }
          }}
        >



          {formik => (



            <form onSubmit={formik.handleSubmit}>
              <div className='card-body border-top p-9'>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Profile Photo</label>
                  <div className='col-lg-8'>
                    <div className='image-input image-input-outline' data-kt-image-input='true'>
                      <div className='image-input-wrapper w-200px h-200px'>

                        

                      {profilePic ? (
                        <img src={profilePic} alt='Profile' className='fixed-size-image' />
                      ) : (
                        <img src={blankprofilepic} alt='Signature' className='fixed-size-image' />
                      )}

                      </div>
                    </div>
                  </div>
                </div>

                {
                  profilePic ?
                    null
                    :
                    <div className='row mb-6'>
                      <label className='col-lg-4 col-form-label fw-bold fs-6'>
                        <span className='required'>Your profile</span>
                      </label>

                      <div className='col-lg-8 fv-row'>
                        <input
                          className='form-control form-control-lg form-control-solid'
                          type='file'
                          id='formFile'
                          required
                          accept='.jpg, .jpeg, .png'
                          onChange={handleImageUpload}
                        />
                      </div>
                    </div>
                }

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>

                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          name='firstname'
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='First name'
                          value={usersResponse?.firstname || ''}
                          onChange={handleChange2}
                        />
                      </div>

                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          name='lastname'
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Last name'
                          value={usersResponse?.lastname}
                          onChange={handleChange2}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Contact Phone</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='tel'
                      name='phone_no'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Phone number'
                      value={usersResponse?.phone_no}
                      onChange={handleChange2}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Experience Years</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='number'
                      name='experience_years'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Certification Details'
                      value={usersResponse?.experience_years}
                      onChange={handleChange2}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Certification Details</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      name='certification_details'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Certification Details'
                      value={usersResponse?.certification_details}
                      onChange={handleChange2}
                    />
                  </div>
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Insurance Details</span>
                  </label>

                  <div className='col-lg-8 fv-row'>
                    <input
                      type='text'
                      name='insurance_details'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Insurance Details'
                      value={usersResponse?.insurance_details}
                      onChange={handleChange2}
                    />
                  </div>
                </div>

              
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Availability</span>
                  </label>
                  <div className='col-lg-8 fv-row'>
                    <Select
                      className='form-select-solid react-select-styled react-select-solid react-select-sm'
                      classNamePrefix='react-select'
                      options={postalcodeList}
                      placeholder='Select an Segment Type'
                      value={postalcodeList.filter(option => availabilities.includes(option.value))}
                      onChange={handleChange}
                      isMulti
                    />
                  </div>
                </div>

                <div className='row mb-6 cursor-pointer'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Conditions</label>

                  <div className='col-lg-8 d-flex justify-content-between'>
                    {/* Terms and Conditions */}
                    <div className='fv-row me-3'>
                      <div onClick={() => handleOpenModal('Terms & Conditions', '/media/pdf/TermsAndConditions.pdf')}
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG path='/media/icons/duotune/communication/com011.svg' className='svg-icon-4 me-1' />
                        Terms & Condition
                      </div>
                      <div className='form-text'>Please Accept Terms & Conditions</div>
                    </div>

                    {/* Cancellation Policy */}
                    <div className='fv-row me-3'>
                      <div
                        onClick={() =>
                          handleOpenModal('Cancellation Policy', '/media/pdf/CancellationPolicy.pdf')
                        }
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        Cancellation Policy
                      </div>
                      <div className='form-text'>Please Accept Cancellation Policy</div>
                    </div>

                    {/* Code of Conduct */}
                    <div className='fv-row'>
                      <div
                        onClick={() =>
                          handleOpenModal('Code of Conduct', '/media/pdf/CodeOfConduct.pdf')
                        }
                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                      >
                        <KTSVG
                          path='/media/icons/duotune/communication/com011.svg'
                          className='svg-icon-4 me-1'
                        />
                        Code of Conduct
                      </div>
                      <div className='form-text'>Please Accept Code of Conduct</div>
                    </div>
                  </div>

                  {/* Modal Component */}
                  <PdfModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    title={modalTitle}
                    pdfUrl={pdfUrl}
                    onAccept={handlecceptance}
                  />
                </div>

                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>
                    <span className='required'>Your Signature</span>
                  </label>

                  {signatureSrc != '' ? (
                    <>
                      <div className='col-lg-6 fv-row' style={{ border: '1px solid black' }}>
                        <img src={signatureSrc} alt='Signature' className='w-80 h-80' />
                      </div>

                      <div className='col-lg-2'>
                        <a
                          className='btn btn-primary mb-2'
                          target='blank'
                          href={signatureSrc}
                          download='signature.png'
                        >
                          Download
                        </a>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='col-lg-6 fv-row' style={{ border: '1px solid black' }}>
                        <SignatureCanvas
                          ref={sigCanvas}
                          penColor='black'
                          canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                        />
                      </div>
                      <div className='col-lg-2'>
                        <button className='btn btn-secondary mb-2' onClick={clearSignature}>
                          Clear
                        </button>
                        <button className='btn btn-dark mb-2' onClick={uploadSignature}>
                          Save
                        </button>
                        <button className='btn btn-primary mb-2' onClick={saveSignature}>
                          Download
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='card-footer d-flex justify-content-end py-6 px-9'>
                <button type='submit' className='btn btn-primary'>
                  save changes
                </button>
              </div>
            </form>
          )}
        </Formik>

      </div>
    </div>
  )
}

function dataURLToBlob(dataURL: string): Blob {
  const byteString = atob(dataURL.split(',')[1])
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}
export { ProfileOverview }
