import axios from 'axios';
import React, { useEffect, useState } from 'react'


interface User {
    user_id: number;
    username: string;
    password: string;
    email: string;
    role: number;
    firstname: string;
    lastname: string;
    phone_no: string;
    is_disabled: boolean;
    profile_photo_url: null | string;
    availability: null | string[];
    signature_url: null | string;
    state_id: number;
}

interface Property {
    property_id: number;
    address: string;
    city: string;
    state: string;
    zipcode: string;
}

interface Inspector {
    inspector_id?: number;
    certification_details?: string;
    insurance_details?: string;
    experience_years?: number;
    rating?: number;
    is_disabled?: boolean;
    firstname?: string;
    lastname?: string;
    phone_no?: string;
    availability?: any[];
    terms?: boolean;
    cancel_policy?: boolean;
    code_conduct?: boolean;
}

interface InspectionRequest {
    request_id: number;
    request_date: string;
    status: boolean;
    inspection_date: null | string;
    requester_role: number;
    assigned_date: null | string;
    accept: boolean;
    property: Property;
    requested_by: User;
    assigned_inspector: null | Inspector;
}

const InspectionReports = () => {

    const [requests, setRequests] = useState<InspectionRequest[]>([]);
    const [loading, setloading] = useState(false);

    const fetchRequests = async () => {
        try {
            
            const response = await axios.get('https://api.inspection.payplatter.in/api/inspection-reports');
            setRequests(response.data);
            setloading(false);
            console.log("inspection reports")
            console.log(response.data)

        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        // const timer = setTimeout(fetchRequests, 3000);
        // return () => clearTimeout(timer);

        fetchRequests();
    }, []);




  return (
    <div>
      <div>
            <div className={`card`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Inspection Reports</span>
                    </h3>
                </div>

                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        {
                            loading ?
                                <span className='center'>Loading Inspection Reports......</span>
                                :
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th className='min-w-150px'>Request Date</th>
                                            <th className='min-w-120px'>Status</th>
                                            <th className='min-w-150px'> Address </th>
                                            <th className='min-w-120px'>Assigned Inspector</th>
                                            <th className='min-w-120px'>Accepted</th>
                                            <th className='min-w-120px'>Action</th>
                                        </tr>
                                    </thead>
                                    {/* <tbody>
                                        {requests.map((request): React.ReactNode => (
                                            <tr key={request.request_id}>
                                                <td>{new Date(request.request_date).toLocaleDateString()}</td>
                                                <td>{request.status ? 'Active' : 'Inactive'}</td>
                                                <td>
                                                    {request.property.address},
                                                    {request.property.city},
                                                    {request.property.state},
                                                    {request.property.zipcode}
                                                </td>
                                                <td>
                                                    {request.assigned_inspector?.firstname} {' '} {request.assigned_inspector?.lastname || notDefined}
                                                </td>
                                                <td>
                                                    {request.accept ? 
                                                    <span className={`badge badge-light-success fs-7 fw-semibold`}>Yes</span>
                                                    :
                                                     <span className={`badge badge-light-danger fs-7 fw-semibold`}>No</span>}
                                                </td>
                                                <td>
                                                    <button className='btn btn-primary btn-sm'>Manage</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody> */}
                                </table>
                        }

                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InspectionReports
