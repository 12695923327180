

import React, { useState } from 'react';
import { KTSVG } from '../../../../_metronic/helpers';

interface InspectionOrder {
  name: string;
  date: string;
  orderId: string;
  status: string;
}

const ManageOrders: React.FC = () => {
  const [orders, setOrders] = useState<InspectionOrder[]>([
    { name: 'Order 1', date: '27/11/2024', orderId: 'D1245', status: 'Completed' },
    { name: 'Order 2', date: '28/11/2024', orderId: 'D1246', status: 'Pending' },
    { name: 'Order 3', date: '29/11/2024', orderId: 'D1247', status: 'Completed' },
    { name: 'Order 4', date: '30/11/2024', orderId: 'D1248', status: 'Pending' },
    { name: 'Order 5', date: '01/12/2024', orderId: 'D1249', status: 'Completed' },
    { name: 'Order 6', date: '02/12/2024', orderId: 'D1250', status: 'Pending' },
    { name: 'Order 7', date: '03/12/2024', orderId: 'D1251', status: 'Completed' },
    { name: 'Order 8', date: '04/12/2024', orderId: 'D1252', status: 'Pending' },
  ]);

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Inspection Orders</span>
        </h3>
      </div>

      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            <thead>
              <tr className='fw-bold text-muted'>
                <th className='min-w-150px'>Inspection Order Name</th>
                {/* <th className='min-w-120px'>Inspection Order Id</th> */}
                <th className='min-w-150px'> payment Date</th>
               
                <th className='min-w-120px'>Status</th>
                <th className='min-w-100px text-end'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => (
                <tr key={index}>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='d-flex flex-column'>
                        <span className='text-dark fw-bold text-hover-primary fs-6'>
                          {order.name}
                        </span>
                      </div>
                    </div>
                  </td>

                  {/* <td>
                    <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {order.orderId}
                    </span>
                  </td> */}
                  <td>
                    <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                      {order.date}
                    </span>
                  </td>
                 
                  <td>
                    <span
                      className={`badge badge-light-${order.status === 'Completed' ? 'success' : 'danger'} fw-bolder fs-8 px-2 py-1`}
                    >
                      {order.status}
                    </span>
                  </td>
                  <td className='text-end'>
                    <div className='d-flex justify-content-end flex-shrink-0'>
                     
                  
                      <button
                        name='Delete'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                        // onClick={() => handleDeleteOrder(order.orderId)}
                      >
                        <KTSVG
                          path='/media/icons/duotune/general/gen027.svg'
                          className='svg-icon-3'
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export { ManageOrders }

