import React from 'react'
import {useLocation} from 'react-router-dom'
import {Container, Card, Row, Col} from 'react-bootstrap'
import demo2 from '../../../../_metronic/assets/7.jpeg'
import Inspectors from './Inspectors'
// import InspectorDemo from './InspectorDemo'

interface LocationState {
  description: string;
}

const PropertyDetailsPage: React.FC = () => {
  const location = useLocation()
  const {description} = location.state as LocationState

  return (
    <>
      <section
        style={{
          // backgroundColor: 'rgb(79, 42, 192)',
          // overflow: 'hidden',
          backgroundImage: `url(${demo2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          padding: '50px 0',
          color: '#ffffff',
        }}
      >
        <div
          style={{
            width: '400px',
            height: '400px',
            backgroundColor: 'rgba(255, 255, 255, 0.03)',
            borderRadius: '50%',
            position: 'absolute',
            top: '0',
          }}
        />
        <div
          style={{
            width: '400px',
            height: '400px',
            backgroundColor: 'rgba(255, 255, 255, 0.03)',
            borderRadius: '50%',
            position: 'absolute',
            right: '0',
            bottom: '0',
          }}
        />
        <Container style={{position: 'relative'}}>
          <Row className='justify-content-center text-center'>
            <Col lg={12}>
              <div
                style={{
                  fontSize: '24px',
                  fontWeight: 'bold',
                  color: '#000000',
                  padding: '2px',
                  position: 'absolute',
                  top: '20px',
                  left: '20px',
                }}
              >
                <span
                  style={{
                    backgroundColor: '#d4edda',
                    color: '#155724',
                    padding: '0.2em 0.6em',
                    borderRadius: '0.25rem',
                  }}
                >
                  Building & Pest Reports
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginTop: '100px',
                }}
              >
                <div
                  style={{
                    width: '70%',
                    textAlign: 'left',
                    paddingRight: '20px',
                  }}
                >
                  <h1
                    style={{
                      fontSize: '32px',
                      color: '#ffffff',
                      margin: '20px 0',
                    }}
                  >
                    {description}
                  </h1>
                </div>
                <div
                  style={{
                    width: '30%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Card
                    style={{
                      height: '120px',
                      width: '250px',
                      background: '#d4edda',
                      border: 'none',
                      borderRadius: '15px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Card.Body className='d-flex flex-column align-items-center justify-content-center'>
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          color: 'black',
                        }}
                      >
                        Price starting at just
                      </p>
                      <p
                        style={{
                          fontSize: '35px',
                          fontWeight: 'bold',
                          color: '#28a745',
                        }}
                      >
                        $249
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <div className='custom-shape-divider-bottom-1723190408'>
          <svg
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 1200 120'
            preserveAspectRatio='none'
          >
            <path
              d='M892.25 114.72L0 0 0 120 1200 120 1200 0 892.25 114.72z'
              className='shape-fill'
            />
          </svg>
        </div>
      </section>

      <style>{`
.custom-shape-divider-bottom-1723190408 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-bottom-1723190408 svg {
    position: relative;
    display: block;
    width: calc(276% + 1.3px);
    height: 61px;
    transform: rotateY(180deg);
}

.custom-shape-divider-bottom-1723190408 .shape-fill {
    fill: #F5F8FA;
}
`}</style>

      <div style={{marginTop: '60px'}}>
        <Inspectors />
        {/* <InspectorDemo></InspectorDemo> */}
      </div>
    </>
  );
};

export default PropertyDetailsPage;
