import React, {useState, useEffect , useRef } from 'react'
import {Button, Col, Form, Modal, Row} from 'react-bootstrap'
import {
  CreateDefect,
  CreateInspectionReport,
  InspectionRequest,
  InspectionRequestOfInspector,
} from '../../users/core/_models'
import {
  createInspectionReport,
  fetchInspectionRequests,
  getInspectionRequestsOfInspector,
} from '../../users/core/_requests'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {startOfMonth, endOfMonth, subMonths, startOfYear, endOfYear} from 'date-fns'
import {useAuth} from '../../auth/core/Auth'
import axios from 'axios'
import {Request} from '../core/_modules'
import {toast} from 'react-toastify'
import {KTSVG} from '../../../../_metronic/helpers'
// import jsPDF from 'jspdf';
import ReactToPrint from 'react-to-print';
import Select from 'react-select';

type Props = {
  className: string
}

const findingsMap: { [key: string]: string } = {
  'Potential organic growth (mould)': 'Mould growth indicates high moisture levels and potential health risks. Commonly found in areas with poor ventilation, it can cause damage to building materials and pose health hazards to occupants if left untreated.\n',
  'Shower - Damp (Major)': 'Significant dampness in the shower area suggests issues with waterproofing or drainage. It can lead to water damage to walls and floors, and potentially cause mold growth if not addressed promptly.\n',
  'Ceiling - Water damaged': 'Water damage on the ceiling often results from leaks in the roof or plumbing issues. It can cause structural damage, staining, and deterioration of the ceiling material, and may require significant repairs to prevent further issues.\n',
  'Cracking - Damage Category 1 - Fine (up to 1mm)': 'Fine cracks up to 1mm in width are usually surface-level and may be caused by minor settling or temperature changes. They typically do not pose a significant structural risk but should be monitored for changes over time.\n',
  'Door - Binding': 'A binding door is one that sticks or is difficult to open and close. This issue can arise from misalignment, humidity changes, or damaged hinges, and may require adjustment or repair to ensure proper function.\n',
  'Door stop - Missing': 'A missing door stop can lead to damage to walls or doors due to uncontrolled swinging. Installing a door stop helps prevent such damage and prolongs the lifespan of both the door and the surrounding structure.\n',
  'Windows - Sash painted shut (all areas)': 'When window sashes are painted shut, they cannot be opened for ventilation. This can be a safety hazard and affect indoor air quality. It is important to address this issue to ensure windows are functional and safe.\n',
  'Cracking - Damage Category 2 - Noticeable (up to 5mm)': 'Noticeable cracks up to 5mm in width may indicate more significant structural issues or settling. These cracks should be inspected by a professional to determine the underlying cause and appropriate remediation measures.\n',
  'Basin - Cracked': 'A cracked basin can lead to leaks and water damage. Cracks in basins, whether in sinks or bathtubs, compromise their integrity and may require repair or replacement to prevent further damage and ensure proper functionality.\n',
  'Bathroom cupboard - Swollen': 'Swelling in a bathroom cupboard often results from prolonged exposure to moisture or high humidity. This can cause the cupboard doors to warp and make them difficult to open or close. Addressing moisture issues is key to preventing further damage.\n',
  'Wall tiles - Cracked': 'Cracked wall tiles can be a sign of underlying issues such as shifting walls or improper installation. Cracks can allow water to seep behind the tiles, leading to further damage. Regular inspection and timely repairs are necessary to maintain tile integrity.\n',
  'Radiator damaged': 'A damaged radiator may result in inefficient heating and potential leaks. Issues could include leaks, corrosion, or physical damage to the radiator. Repairing or replacing damaged radiators is crucial for maintaining effective heating in the space.\n',
  'Wall panel - Water damaged': 'Water damage to wall panels can result from leaks or high humidity. It can cause staining, warping, or mold growth. Addressing the source of water intrusion and repairing or replacing damaged panels is essential to prevent further damage.\n',
  'Grout/caulk - Deteriorated': 'Deteriorated grout or caulk around tiles can lead to water penetration and potential damage to underlying surfaces. Replacing worn-out grout or caulk helps maintain the integrity of tiled areas and prevents water-related issues.\n',
  'Door handle incorrectly installed': 'An incorrectly installed door handle can affect the door’s functionality and security. It may cause difficulty in opening or closing the door and may require adjustment or reinstallation to ensure proper operation.\n',
  'Wood rot - Pergola framing': 'Wood rot in pergola framing indicates prolonged exposure to moisture, leading to deterioration of the wood. This can compromise the structural integrity of the pergola and may require replacement or treatment of affected wood to prevent further damage.\n'
};


const findingsOptions = Object.keys(findingsMap).map(key => ({
  value: key,
  label: key,
}));

const InspectionsReports: React.FC<Props> = ({className}) => {
  const [showModal, setShowModal] = useState(false)
  const [showAcceptModal, setShowAcceptModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showAddReportModal, setShowAddReportModal] = useState(false)
  const [selectedInspection, setSelectedInspection] = useState<InspectionRequest | null>(null)
  const [filter, setFilter] = useState<string>('all')
  const [dateFilter, setDateFilter] = useState<string>('all')
  const [customStartDate, setCustomStartDate] = useState<string>('')
  const [fetchedByIdData, setFetchedByIdData] = useState([])
  const [customEndDate, setCustomEndDate] = useState<string>('')
  const [inspections, setInspections] = useState<InspectionRequest[]>([])



  const [safetyHazards, setSafetyHazards] = useState<CreateDefect[]>([
    { type: 'Safety Hazard', building: '', location: '', finding: '', information: '', images: [] },
  ]);

  const [majorDefects, setMajorDefects] = useState<CreateDefect[]>([
    { type: 'Major Defects', building: '', location: '', finding: '', information: '', images: [] },
  ]);
  const [minorDefects, setMinorDefects] = useState<CreateDefect[]>([
    { type: 'Minor Defects', building: '', location: '', finding: '', information: '', images: [] },
  ]);
  const [furtherInspections, setFurtherInspections] = useState<CreateDefect[]>([
    { type: 'Further Inspections', building: '', location: '', finding: '', information: '', images: [] },
  ]);
  const [notedItems, setNotedItems] = useState<CreateDefect[]>([
    { type: 'Noted Items', building: '', location: '', finding: '', information: '', images: [] },
  ]);

  const [cameraStream, setCameraStream] = useState<MediaStream | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const handleAddSafetyHazard = () => {
    setSafetyHazards((prev) => [
      ...prev,
      {type: 'Safety Hazard', building: '', location: '', finding: '', information: '', images: []},
    ])
  }

  const handleAddMajorDefect = () => {
    setMajorDefects((prev) => [
      ...prev,
      {type: 'Major Defect', building: '', location: '', finding: '', information: '', images: []},
    ])
  }

  const handleAddMinorDefect = () => {
    setMinorDefects((prev) => [
      ...prev,
      {type: 'Minor Defect', building: '', location: '', finding: '', information: '', images: []},
    ])
  }

  const handleAddFurtherInspection = () => {
    setFurtherInspections((prev) => [
      ...prev,
      {
        type: 'Further Inspection',
        building: '',
        location: '',
        finding: '',
        information: '',
        images: [],
      },
    ])
  }

  const handleAddNotedItem = () => {
    setNotedItems((prev) => [
      ...prev,
      {type: 'Noted item', building: '', location: '', finding: '', information: '', images: []},
    ])
  }

  const handleRemoveSafetyHazard = (index: number) => {
    setSafetyHazards(safetyHazards.filter((_, i) => i !== index))
  }
  const handleRemoveMajorDefect = (index: number) => {
    setMajorDefects(majorDefects.filter((_, i) => i !== index))
  }
  const handleRemoveMinorDefect = (index: number) => {
    setMinorDefects(minorDefects.filter((_, i) => i !== index))
  }
  const handleRemoveFurtherInspection = (index: number) => {
    setFurtherInspections(furtherInspections.filter((_, i) => i !== index))
  }
  const handleRemoveNotedItem = (index: number) => {
    setNotedItems(notedItems.filter((_, i) => i !== index))
  }

  const handleSafetyHazardChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target
    setSafetyHazards((prev) => {
      const updated = [...prev]
      updated[index] = {
        ...updated[index],
        [name]: value,
      }
      return updated
    })
  }

  const handleMajorDefectChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target

    const updatedMajorDefects = [...majorDefects]
    updatedMajorDefects[index] = {
      ...updatedMajorDefects[index],
      [name]: value,
    }

    setMajorDefects(updatedMajorDefects)
  }

  const handleMinorDefectChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target

    const updatedMinorDefects = [...minorDefects]
    updatedMinorDefects[index] = {
      ...updatedMinorDefects[index],
      [name]: value,
    }

    setMinorDefects(updatedMinorDefects)
  }

  const handleFurtherInspectionChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {name, value} = event.target

    const updatedFurtherInspections = [...furtherInspections]
    updatedFurtherInspections[index] = {
      ...updatedFurtherInspections[index],
      [name]: value,
    }

    setFurtherInspections(updatedFurtherInspections)
  }

  const handleNotedItemChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const {name, value} = event.target
      const updatedNotedItems = [...notedItems]
      updatedNotedItems[index] = {...updatedNotedItems[index], [name]: value}
      setNotedItems(updatedNotedItems)
    }

  const handleSafetyHazardImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setSafetyHazards((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleMajorDefectImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setMajorDefects((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleMinorDefectImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setMinorDefects((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleFurtherInspectionImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setFurtherInspections((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const handleNotedItemImageChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files
    if (files) {
      const imageUrls = Array.from(files).map((file) => URL.createObjectURL(file))
      setNotedItems((prev) => {
        const updated = [...prev]
        updated[index] = {
          ...updated[index],
          images: imageUrls,
        }
        return updated
      })
    }
  }

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setCameraStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current?.play();
        };
      }
    } catch (error) {
      console.error('Error accessing camera:', error);
      toast.error('Failed to access camera');
    }
  };

  const takePhoto = (index: number) => {
    if (videoRef.current && canvasRef.current) {
      const context = canvasRef.current.getContext('2d');
      if (context) {
        // Set the canvas dimensions to the video dimensions
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        // Draw the current frame from the video onto the canvas
        context.drawImage(videoRef.current, 0, 0, videoRef.current.videoWidth, videoRef.current.videoHeight);
        const imageUrl = canvasRef.current.toDataURL('image/png');
        setSafetyHazards((prev) => {
          const updated = [...prev];
          updated[index] = {
            ...updated[index],
            images: [...updated[index].images, imageUrl],
          };
          return updated;
        });
      }
    }
  };

  const stopCamera = () => {
    if (cameraStream) {
      cameraStream.getTracks().forEach((track) => track.stop());
      setCameraStream(null);
    }
  };

  useEffect(() => {
    return () => {
      stopCamera(); // Stop camera when component unmounts
    };
  }, []);

  const fetchInspectionRequests = async () => {
    try {
      const response = await axios.get('https://api.inspection.payplatter.in/api/inspection-requests')
      setInspections(response.data)
    } catch (error) {
      console.error('Error fetching inspection requests:', error)
      toast.error('Failed to fetch inspection requests')
    }
  }

  // Fetch inspection requests on component mount
  useEffect(() => {
    fetchInspectionRequests()
  }, [])

  const auth = useAuth()

  const currentUser = auth.currentUser
  // console.log(currentUser)
  const currenteUserId = currentUser?.user_id
  const currenteUserRole = currentUser?.role
  const currenteUserPhoneNum = currentUser?.phone_no
  const currenteUserName = currentUser?.firstname + ' ' + currentUser?.lastname

  useEffect(() => {
    const loadData = async () => {
      const data = await getInspectionRequestsOfInspector(currenteUserId)
      setInspections(data)
    }
    loadData()
  }, [])

  const handleOpenModal = (inspection: InspectionRequest) => {
    setSelectedInspection(inspection)
    setShowModal(true)
  }

  const handleCloseModal = () => {
    setShowModal(false)
    setShowAddReportModal(false)
    setSelectedInspection(null)
  }

  const handleOpenAcceptModal = (inspection: InspectionRequest) => {
    setSelectedInspection(inspection)
    setShowAcceptModal(true)
    setShowModal(false)
  }

  const handleCloseAcceptModal = () => {
    setShowAcceptModal(false)
    setSelectedInspection(null)
    setShowModal(false)
  }

  const handleOpenRejectModal = (inspection: InspectionRequest) => {
    setSelectedInspection(inspection)
    setShowRejectModal(true)
    setShowModal(false)
  }

  const handleCloseRejectModal = () => {
    setShowRejectModal(false)
    setSelectedInspection(null)
    setShowModal(false)
  }

  const handleOpenAddReportModal = () => setShowAddReportModal(true)
  const handleCloseAddReportModal = () => setShowAddReportModal(false)

  // console.log(inspections)

  const getFilteredInspections = () => {
    let filtered = inspections.filter((inspection) => {
      if (filter === 'all') return true
      if (filter === 'active') return inspection.status
      if (filter === 'completed') return !inspection.status
      return true
    })

    const today = new Date()

    if (dateFilter === 'thisMonth') {
      const start = startOfMonth(today)
      const end = endOfMonth(today)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    } else if (dateFilter === 'lastThreeMonths') {
      const start = subMonths(startOfMonth(today), 3)
      const end = endOfMonth(today)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    } else if (dateFilter === 'year') {
      const start = startOfYear(today)
      const end = endOfYear(today)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    } else if (dateFilter === 'custom' && customStartDate && customEndDate) {
      const start = new Date(customStartDate)
      const end = new Date(customEndDate)
      filtered = filtered.filter((inspection) => {
        const requestDate = new Date(inspection.request_date)
        return requestDate >= start && requestDate <= end
      })
    }

    return filtered
  }

  const handleDateFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDateFilter(e.target.value)
    if (e.target.value !== 'custom') {
      setCustomStartDate('')
      setCustomEndDate('')
    }
  }

  const loadData = async () => {
    const data = await getInspectionRequestsOfInspector(currenteUserId)
    setInspections(data)
  }

  useEffect(() => {
    
    loadData()
  }, [])


  const fetchInspectionRequestById = async (id : number) => {
    try {
      const response = await axios.get(`https://api.inspection.payplatter.in/api/inspection-requests/${id}`)
      setFetchedByIdData(response.data);
    } catch (error) {
      console.error('Error fetching inspection request:', error)
      toast.error('Failed to fetch inspection request')
    }
  }

  const handleAcceptInspectionRequest = (id: number) => {
    console.log('Hanlde Accrept Inspection Called')
    axios.put(`https://api.inspection.payplatter.in/api/inspection-requests/${id}/accept_request`)
     .then(() => {
        toast.success('Inspection request accepted')
        fetchInspectionRequests()
        handleCloseAcceptModal()
        fetchInspectionRequestById(id)
        loadData()
      })
     .catch((error) => {
        console.error('Error accepting inspection request:', error)
        toast.error('Failed to accept inspection request')
      })
  }

  const handleDeassignInspectorRequest = async (requestId: number) => {
    try {
      await axios.put(`https://api.inspection.payplatter.in/api/inspection-requests/${requestId}/deassign`)

      setSelectedInspection((prevInspections) =>
        prevInspections
          ? prevInspections.map((inspection: InspectionRequest) =>
              inspection.request_id === requestId
                ? {...inspection, assigned_inspector: null, assigned_date: null, status: false}
                : inspection
            )
          : null
      )

      toast.success('Inspector de-assigned successfully')
      handleCloseRejectModal() // Close the modal after successful API call
      fetchInspectionRequests()

      // Refresh the page
      window.location.reload()
    } catch (error) {
      console.error('Error de-assigning inspector:', error)
      toast.error('Failed to de-assign inspector')
    }
  }

  const [formData, setFormData] = useState<CreateInspectionReport>({
    inspectionDate: new Date(),
    propertyAddress: '',
    city: '',
    state: '',
    zipcode: '',
    clientName: '',
    principalName: '',
    jobAddress: '',
    clientEmail: '',
    clientPhoneNumber: '',
    consultant: '',
    companyName: '',
    companyAddress: '',
    companyPostcode: '',
    companyEmail: '',
    companyContactNumbers: '',
    safetyHazard: false,
    majorDefect: false,
    minorDefect: false,
    overallCondition: '',
    buildingType: [],
    companyOrStrataTitle: false,
    floor: '',
    furnished: false,
    numberOfBedrooms: 0,
    occupied: false,
    orientation: '',
    otherBuildingElements: '',
    otherTimberBuildingElements: '',
    roof: '',
    storeys: '',
    walls: '',
    weather: '',
    areasInspected: '',
    inaccessibleAreas: '',
    obstructionsOrLimitations: '',
    riskOfUndetectedDefects: '',
    defects: [],
    additionalComments: '',
    recommendations: '',
   
  })

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
  console.log(
    formData.safetyHazard
  )
    const data: CreateInspectionReport = {
      ...formData,
      defects: [
        ...safetyHazards,
        ...majorDefects,
        ...minorDefects,
        ...furtherInspections,
        ...notedItems,
      ],
    }

    try {
     
      const response = await axios.post('https://api.inspection.payplatter.in/api/inspection-reports', data, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      console.log('submitted')
      console.log(response)
      // Clear form and defect states
      setFormData({
        inspectionDate: new Date(),
        propertyAddress: '',
        city: '',
        state: '',
        zipcode: '',
        clientName: '',
        principalName: '',
        jobAddress: '',
        clientEmail: '',
        clientPhoneNumber: '',
        consultant: '',
        companyName: '',
        companyAddress: '',
        companyPostcode: '',
        companyEmail: '',
        companyContactNumbers: '',
        safetyHazard: false,
        majorDefect: false,
        minorDefect: false,
        overallCondition: '',
        buildingType: [],
        companyOrStrataTitle: false,
        floor: '',
        furnished: false,
        numberOfBedrooms: 0,
        occupied: false,
        orientation: '',
        otherBuildingElements: '',
        otherTimberBuildingElements: '',
        roof: '',
        storeys: '',
        walls: '',
        weather: '',
        areasInspected: '',
        inaccessibleAreas: '',
        obstructionsOrLimitations: '',
        riskOfUndetectedDefects: '',
        defects: [],
        additionalComments: '',
        recommendations: '',
      })
      setSafetyHazards([])
      setMajorDefects([])
      setMinorDefects([])
      setFurtherInspections([])
      setNotedItems([])
    } catch (error) {
      console.error('Failed to create report:', error)
      console.log(error)
      toast.error('Failed to create inspection report.')
    }
  }

  function handleChange(event: React.ChangeEvent<{name?: string; value: any}>) {
    const {name, value} = event.target
    setFormData((prevState) => ({
      ...prevState,
      [name!]: value, // value is an array of selected options
    }))
  }

  const handleDateChange = (date: Date | null) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      inspectionDate: date as Date,
    }))
  }


  
  const reportStyles = {
    receipt: {
      maxWidth: '100%',
      margin: '0',
      padding: '20px',
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '10px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '2px solid #007bff',
      paddingBottom: '15px',
      marginBottom: '20px',
    },
    logo: {
      maxWidth: '150px',
    },
    logoImage: {
      maxWidth: '100%',
    },
    info: {
      textAlign: 'right',
    },
    companyName: {
      fontSize: '26px',
      color: '#007bff',
      margin: 0,
    },
    companyAddress: {
      fontSize: '14px',
      color: '#666',
      margin: '5px 0',
    },
    section: {
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    },
    sectionTitle: {
      fontSize: '22px',
      color: '#333',
      backgroundColor: '#e9ecef',
      padding: '12px',
      borderRadius: '8px',
      margin: 0,
      borderLeft: '5px solid #007bff',
    },
    details: {
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    },
    subSectionTitle: {
      fontSize: '18px',
      color: '#007bff',
      marginTop: '10px',
      marginBottom: '10px',
    },
    footer: {
      textAlign: 'center',
      fontSize: '14px',
      color: '#777',
      marginTop: '30px',
      borderTop: '1px solid #ddd',
      paddingTop: '15px',
    },
    checkbox: {
      marginRight: '10px',
    },
    defectItem: {
      marginBottom: '15px',
      padding: '10px',
      border: '1px solid #eee',
      borderRadius: '5px',
      backgroundColor: '#f9f9f9',
      pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
    },
    fieldLabel: {
      fontWeight: 'bold',
    },
    documents: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '10px',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent document items from breaking
    },
  };
  
  


  const previewRef = useRef();

  const componentRef = useRef<HTMLDivElement>(null);


  

  const handleFindingChange = (index: number, selectedOptions: any) => {
    const findings = selectedOptions.map((option: any) => option.value).join(', ');
    const descriptions = selectedOptions.map((option: any) => findingsMap[option.value] || '').join('\n');

    setSafetyHazards((prev) => {
      const updated = [...prev];
      updated[index] = {
        ...updated[index],
        finding: findings,
        information: descriptions,
      };
      return updated;
    });
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleShowConfirmModal = () => setShowConfirmModal(true);
  const handleCloseConfirmModal = () => setShowConfirmModal(false);

  const handleShowUploadModal = () => setShowUploadModal(true);
  const handleCloseUploadModal = () => setShowUploadModal(false);

  const handleConfirm = () => {
    handleCloseConfirmModal();
    handleShowUploadModal();
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      // Handle file upload logic here
      console.log('Files to upload:', files);
    }
  };

  return (
    <>
      <div className={`card ${className}`}>
        <div className='card-header border-0 pt-5 d-flex justify-content-between'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Inspections List</span>
          </h3>
          <div className='d-flex align-items-center'>
            <select
              className='form-select form-select-sm me-3'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            >
              <option value='all'>All Status</option>
              <option value='active'>Completed</option>
              <option value='completed'>Incomplete</option>
            </select>

            <select
              className='form-select form-select-sm mt-2 me-3'
              value={dateFilter}
              onChange={handleDateFilterChange}
            >
              <option value='all'>Date Range</option>
              <option value='thisMonth'>This Month</option>
              <option value='lastThreeMonths'>Last Three Months</option>
              <option value='year'>This Year</option>
              <option value='custom'>Custom</option>
            </select>
            {dateFilter === 'custom' && (
              <div className='d-flex flex-column position-relative'>
                <div className='d-flex'>
                  <input
                    type='date'
                    className='form-control form-control-sm me-2'
                    value={customStartDate}
                    onChange={(e) => setCustomStartDate(e.target.value)}
                  />
                  <input
                    type='date'
                    className='form-control form-control-sm me-2'
                    value={customEndDate}
                    onChange={(e) => setCustomEndDate(e.target.value)}
                  />
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={() => setDateFilter('custom')}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              <thead>
                <tr className='fw-bold text-muted bg-light'>
                  <th className='ps-4 min-w-200px rounded-start'>Requester</th>
                  <th className='min-w-200px'>Type</th>
                  <th className='min-w-200px'>Property Details</th>
                  <th className='min-w-200px'>Request Date</th>
                  <th className='min-w-200px'>Assigned Date</th>
                  <th className='min-w-200px'>Inspection Date</th>
                  <th className='min-w-150px'>Status</th>
                  <th className='min-w-150px'>Accepted/Rejected</th>
                  <th className='min-w-300px text-center rounded-end'>Actions</th>
                </tr>
              </thead>
              <tbody>
                {getFilteredInspections().map((inspection) => (
                  <tr key={inspection.request_id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bold text-hover-primary mb-1 fs-6'>
                            {inspection.requested_by.firstname} {inspection.requested_by.lastname}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {inspection.requester_role}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {inspection.property.address}, {inspection.property.city},{' '}
                        {inspection.property.state} - {inspection.property.zipcode}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(inspection.request_date).toLocaleDateString()}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {new Date(inspection.assigned_date).toLocaleDateString()}
                      </a>
                    </td>
                    <td>
                      <a
                        href='#'
                        className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                      >
                        {inspection.inspection_date
                          ? new Date(inspection.inspection_date).toLocaleDateString()
                          : 'Not Started'}
                      </a>
                    </td>




                    <td>
                      <span
                        className={`badge badge-light-${
                          inspection.status ? 'success' : 'danger'
                        } fs-7 fw-semibold`}
                      >
                        {inspection.status ? 'Completed' : 'Incomplete'}
                      </span>
                    </td>




                    
                    <td>
                      <span
                        className={`badge badge-light-${inspection.accept ? 'success' : 'danger'  } fs-7 fw-semibold`}
                      >
                        {inspection.accept ? 'Accepted' : 'Not Accepted'} 
                      </span>
                    </td>



                    <td className='text-center'>
                      {inspection.accept  ?
                       <>
                        <button
                        className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                        style={{color: 'white'}}
                        onClick={() => handleOpenAddReportModal()}
                      >
                        Add Report
                      </button>
                      <button
                        className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4'
                        style={{color: 'white'}}
                        onClick={() => handleShowConfirmModal()}
                      >
                        Upload Report
                      </button>
                     </>:<><button
                       className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                       style={{color: 'white'}}
                       onClick={() => handleOpenModal(inspection)}
                     >
                       Take Action
                     </button></>}
                    
                      {/* <button
                        className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2'
                        style={{color: 'white'}}
                        onClick={() => handleOpenModal(inspection)}
                      >
                        Take Action
                      </button>
                      <button
                        className='btn btn-bg-dark btn-color-muted btn-active-color-primary btn-sm px-4 me-2 mb-2'
                        style={{color: 'white'}}
                        onClick={() => handleOpenAddReportModal()}
                      >
                        Add Report
                      </button> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Confirmation Modal */}
      <Modal show={showConfirmModal} onHide={handleCloseConfirmModal} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Sending Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to send this report to the respective client?
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseConfirmModal}>
            Cancel
          </Button>
          <Button variant='primary' onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

        {/* Upload Modal */}
      <Modal show={showUploadModal} onHide={handleCloseUploadModal} size='lg' centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Upload PDF Report</Form.Label>
              <Form.Control
                type="file"
                accept=".pdf"
                onChange={handleUpload}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleCloseUploadModal}>
            Close
          </Button>
          <Button variant='primary' onClick={handleCloseUploadModal}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

        {/* View Inspection Modal */}
        <Modal
          show={showModal}
          onHide={handleCloseModal}
          centered
          size='lg'
          dialogClassName='modal-90w'
        >
          <Modal.Header closeButton>
            <Modal.Title>Inspection Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className='p-4' style={{maxHeight: '75vh', overflowY: 'auto'}}>
            {selectedInspection && (
              <div className='bg-light p-4 rounded shadow-sm'>
                <h5 className='mb-3 text-primary'>
                  {new Date(selectedInspection.request_date).toLocaleDateString()}
                </h5>
                <div className='mb-3'>
                  <strong>Requester:</strong>
                  <span className='ms-2'>
                    {selectedInspection.requested_by.firstname}{' '}
                    {selectedInspection.requested_by.lastname}
                  </span>
                </div>
                <div className='mb-3'>
                  <strong>Requester Role:</strong>
                  <span className='ms-2'>{selectedInspection.requester_role}</span>
                </div>
                <div className='mb-3'>
                  <strong>Property Address:</strong>
                  <span className='ms-2'>{selectedInspection.property.address}</span>
                </div>
                <div className='mb-3'>
                  <strong>City:</strong>
                  <span className='ms-2'>{selectedInspection.property.city}</span>
                </div>
                <div className='mb-3'>
                  <strong>State:</strong>
                  <span className='ms-2'>{selectedInspection.property.state}</span>
                </div>
                <div className='mb-3'>
                  <strong>Zipcode:</strong>
                  <span className='ms-2'>{selectedInspection.property.zipcode}</span>
                </div>
                <div className='mb-3'>
                  <strong>Inspection got assigned to you on:</strong>
                  <span className='ms-2'>
                    {new Date(selectedInspection.assigned_date).toLocaleDateString()}
                  </span>
                </div>

                <div className='mb-3'>
                  <strong>Status:</strong>
                  <span
                    className={`badge ${
                      selectedInspection.status ? 'bg-success' : 'bg-danger'
                    } ms-2`}
                  >
                    {selectedInspection.status ? 'Completed' : 'Incomplete'}
                  </span>
                </div>
                <div
                  style={{width: '80%', height: '1px', backgroundColor: '#ccc', margin: '10px 0px'}}
                ></div>
                <div className='mb-3'>
                  <strong>Accept OR Reject The Inspection Request:</strong>
                  <span className='ms-2'>
                    <button
                      className='btn btn-sm px-4 me-2'
                      style={{backgroundColor: 'green', color: 'white'}}
                      onClick={() => handleOpenAcceptModal(selectedInspection)}
                    >
                      Accept
                    </button>
                    <button
                      className='btn btn-sm px-4'
                      style={{backgroundColor: 'red', color: 'white'}}
                      onClick={() => handleOpenRejectModal(selectedInspection)}
                    >
                      Reject
                    </button>
                  </span>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>

        {/* View Accept Modal 1 */}
        {/* Modal for accepting an inspection request */}
        <Modal show={showAcceptModal} onHide={handleCloseAcceptModal} size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Accept Inspection Request?</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseAcceptModal}>
              Close
            </Button>

            <Button
              variant='primary'
              onClick={() => {
                if (selectedInspection?.request_id !== undefined) {
                  handleAcceptInspectionRequest(selectedInspection.request_id);
                } else {
                  console.error('Inspection request ID is not available');
                }
              }}
            >
              Accept
            </Button>

          </Modal.Footer>
        </Modal>
        {/* View Reject Modal 2 */}
        {/* Modal for rejecting an inspection request */}
        <Modal show={showRejectModal} onHide={handleCloseRejectModal} size='lg' centered>
          <Modal.Header closeButton>
            <Modal.Title>Reject Inspection Request</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button variant='secondary' onClick={handleCloseRejectModal}>
              Close
            </Button>
            <Button
              variant='danger'
              onClick={() => {
                if (selectedInspection) {
                  handleDeassignInspectorRequest(selectedInspection.request_id)
                }
              }}
            >
              Reject
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Add Report Modal */}
        <Modal show={showAddReportModal} onHide={handleCloseModal} size='lg' fullscreen>
          <Modal.Header closeButton>
            <Modal.Title>Add Report</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
      display: 'flex',
    height: '80vh',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
    }}>
          {/* <Row> */}
      {/* Form Section */}
      {/* <Col md={7}> */}
      <div style={{
    flex: 1,
    overflowY: 'auto',
    paddingRight: '20px',
    borderRight: '2px solid #ccc',
    height: '100%',
  }}>
            {/* <Form onSubmit={handleSubmit} onClick={() => setShowAddReportModal(false)}> */}
            <Form onSubmit={handleSubmit}>
              {/* Section 1: Basic Information */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                Basic Information
              </h5>
              <Row>
                <Col md={6}>
                  <Form.Group controlId='inspectionDate'>
                    <Form.Label>Inspection Date &nbsp;</Form.Label>
                    <DatePicker
                      selected={formData.inspectionDate}
                      placeholderText='Select Date'
                      onChange={handleDateChange}
                      className='form-control'
                      id='inspectionDate'
                      name='inspectionDate'
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-5'>
                <Col md={6}>
                  <Form.Group controlId='propertyAddress'>
                    <Form.Label>Property Address</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter property address'
                      name='propertyAddress'
                      value={formData.propertyAddress}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='city'>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter city'
                      name='city'
                      value={formData.city}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='state'>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter state'
                      name='state'
                      value={formData.state}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='zipcode'>
                    <Form.Label>Zipcode</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter zipcode'
                      name='zipcode'
                      value={formData.zipcode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section 2: The Parties */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                The Parties
              </h5>
              <Row>
                <Col md={6}>
                  <Form.Group controlId='clientName'>
                    <Form.Label>Client Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter client name'
                      name='clientName'
                      value={formData.clientName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='principalName'>
                    <Form.Label>Name of the Principal (If Applicable)</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter name of the principal'
                      name='principalName'
                      value={formData.principalName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='jobAddress'>
                    <Form.Label>Job Address</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter job address'
                      name='jobAddress'
                      value={formData.jobAddress}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='clientEmail'>
                    <Form.Label>Client's Email Address</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter client email'
                      name='clientEmail'
                      value={formData.clientEmail}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='clientPhoneNumber'>
                    <Form.Label>Client's Phone Number</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter client phone number'
                      name='clientPhoneNumber'
                      value={formData.clientPhoneNumber}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='consultant'>
                    <Form.Label>Consultant</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter consultant name'
                      name='consultant'
                      value={formData.consultant}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section 3: Company Details */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                Company Details
              </h5>
              <Row>
                <Col md={6}>
                  <Form.Group controlId='companyName'>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter company name'
                      name='companyName'
                      value={formData.companyName}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='companyAddress'>
                    <Form.Label>Company Address</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter company address'
                      name='companyAddress'
                      value={formData.companyAddress}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='companyPostcode'>
                    <Form.Label>Company Postcode</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter company postcode'
                      name='companyPostcode'
                      value={formData.companyPostcode}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='companyEmail'>
                    <Form.Label>Company Email</Form.Label>
                    <Form.Control
                      type='email'
                      placeholder='Enter company email'
                      name='companyEmail'
                      value={formData.companyEmail}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='companyContactNumbers'>
                    <Form.Label>Company Contact Numbers</Form.Label>
                    <Form.Control
                      type='text'
                      placeholder='Enter company contact numbers'
                      name='companyContactNumbers'
                      value={formData.companyContactNumbers}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section A: Results of Inspection - Summary */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                Section A: Results of Inspection - Summary
              </h5>
              <Row>
              <Col md={4}>
  <Form.Group controlId='safetyHazard'>
    <Form.Label>Safety Hazard</Form.Label>
    <Form.Check
      type='checkbox'
      name='safetyHazard'
      checked={formData.safetyHazard}
      onChange={(e) =>
        setFormData({ ...formData, safetyHazard: e.target.checked })
      }
    />
  </Form.Group>
</Col>

<Col md={4}>
  <Form.Group controlId='majorDefect'>
    <Form.Label>Major Defect</Form.Label>
    <Form.Check
      type='checkbox'
      name='majorDefect'
      checked={formData.majorDefect}
      onChange={(e) =>
        setFormData({ ...formData, majorDefect: e.target.checked })
      }
    />
  </Form.Group>
</Col>

<Col md={4}>
  <Form.Group controlId='minorDefect'>
    <Form.Label>Minor Defect</Form.Label>
    <Form.Check
      type='checkbox'
      name='minorDefect'
      checked={formData.minorDefect}
      onChange={(e) =>
        setFormData({ ...formData, minorDefect: e.target.checked })
      }
    />
  </Form.Group>
</Col>

              </Row>

              <Row className='mt-3'>
                <Col md={12}>
                  <Form.Group controlId='overallCondition'>
                    <Form.Label>Overall Condition</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='overallCondition'
                      placeholder='Enter overall condition'
                      value={formData.overallCondition}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section B: General Description of the Property */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                Section B: General Description of the Property
              </h5>
              <Row>
              <Col md={6}>
  <Form.Group controlId='buildingType'>
    <Form.Label>Building Type</Form.Label>
    <div>
      {['Residential', 'Commercial', 'Industrial'].map((type) => (
        <Form.Check
          key={type}
          type='checkbox'
          label={type}
          value={type}
          checked={formData.buildingType.includes(type)}
          onChange={(e) => {
            const { checked, value } = e.target;
            setFormData((prevState) => {
              const newBuildingType = checked
                ? [...prevState.buildingType, value]
                : prevState.buildingType.filter((t) => t !== value);

              return {
                ...prevState,
                buildingType: newBuildingType,
              };
            });
          }}
        />
      ))}
    </div>
  </Form.Group>
</Col>

<Col md={6}>
  <Form.Group controlId="companyOrStrataTitle">
    <Form.Label>Company or Strata Title</Form.Label>
    <Form.Check
      type="checkbox"
      name="companyOrStrataTitle"
      checked={formData.companyOrStrataTitle}
      onChange={(e) => {
        const { checked } = e.target;
        setFormData((prevState) => ({
          ...prevState,
          companyOrStrataTitle: checked,
        }));
      }}
    />
  </Form.Group>
</Col>

              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='floor'>
                    <Form.Label>Floor</Form.Label>
                    <Form.Control
                      type='text'
                      name='floor'
                      placeholder='Enter floor'
                      value={formData.floor}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                <Form.Group controlId="furnished">
  <Form.Label>Furnished</Form.Label>
  <Form.Control
    as="select"
    name="furnished"
    value={formData.furnished ? 'Yes' : 'No'}
    onChange={(e) => {
      const value = e.target.value;
      setFormData((prevState) => ({
        ...prevState,
        furnished: value === 'Yes',
      }));
    }}
  >
    <option value="">Select</option>
    <option value="Yes">Yes</option>
    <option value="No">No</option>
  </Form.Control>
</Form.Group>

                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                <Form.Group controlId="numberOfBedrooms">
  <Form.Label>No. of Bedrooms</Form.Label>
  <Form.Control
    type="number"
    name="numberOfBedrooms"
    placeholder="Enter number of bedrooms"
    value={
      formData.numberOfBedrooms !== undefined
        ? formData.numberOfBedrooms.toString()
        : ''
    }
    onChange={(e) => {
      const { name, value } = e.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]: value ? parseInt(value, 10) : undefined, // Parse value to number or set as undefined if empty
      }));
    }}
  />
</Form.Group>

                </Col>
                <Col md={6}>
                <Form.Group controlId="occupied">
  <Form.Label>Occupied</Form.Label>
  <Form.Control
    as="select"
    name="occupied"
    value={formData.occupied.toString()} // Convert boolean to string
    onChange={(event) => {
      setFormData({
        ...formData,
        occupied: event.target.value === 'true', // Convert string back to boolean
      });
    }}
  >
    <option value="true">Yes</option>
    <option value="false">No</option>
  </Form.Control>
</Form.Group>

                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                <Form.Group controlId="orientation">
  <Form.Label>Orientation</Form.Label>
  <Form.Control
    as="select"
    name="orientation"
    value={formData.orientation}
    onChange={handleChange}
  >
    <option value="">Select</option>
    <option value="North">North</option>
    <option value="South">South</option>
    <option value="East">East</option>
    <option value="West">West</option>
  </Form.Control>
</Form.Group>

                </Col>
                <Col md={6}>
                  <Form.Group controlId='otherBuildingElements'>
                    <Form.Label>Other Building Elements</Form.Label>
                    <Form.Control
                      type='text'
                      name='otherBuildingElements'
                      placeholder='Enter other building elements'
                      value={formData.otherBuildingElements}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='otherTimberBuildingElements'>
                    <Form.Label>Other Timber Building Elements</Form.Label>
                    <Form.Control
                      type='text'
                      name='otherTimberBuildingElements'
                      placeholder='Enter other timber building elements'
                      value={formData.otherTimberBuildingElements}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='roof'>
                    <Form.Label>Roof</Form.Label>
                    <Form.Control
                      type='text'
                      name='roof'
                      placeholder='Enter roof details'
                      value={formData.roof}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='storeys'>
                    <Form.Label>Storeys</Form.Label>
                    <Form.Control
                      type='number'
                      name='storeys'
                      placeholder='Enter number of storeys'
                      value={formData.storeys}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId='walls'>
                    <Form.Label>Walls</Form.Label>
                    <Form.Control
                      type='text'
                      name='walls'
                      placeholder='Enter wall details'
                      value={formData.walls}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId='weather'>
                    <Form.Label>Weather</Form.Label>
                    <Form.Control
                      type='text'
                      name='weather'
                      placeholder='Enter weather details'
                      value={formData.weather}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                Section C: Accessibility
              </h5>
              <Row className='mt-3'>
                <Col md={12}>
                  <Form.Group controlId='areasInspected'>
                    <Form.Label>Areas Inspected</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='areasInspected'
                      placeholder='Enter areas inspected'
                      value={formData.areasInspected}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={12}>
                  <Form.Group controlId='inaccessibleAreas'>
                    <Form.Label>Inaccessible Areas</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='inaccessibleAreas'
                      placeholder='Enter inaccessible areas'
                      value={formData.inaccessibleAreas}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={12}>
                  <Form.Group controlId='obstructionsOrLimitations'>
                    <Form.Label>Obstructions/Limitations</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='obstructionsOrLimitations'
                      placeholder='Enter obstructions and limitations'
                      value={formData.obstructionsOrLimitations}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className='mt-3'>
                <Col md={12}>
                  <Form.Group controlId='riskOfUndetectedDefects'>
                    <Form.Label>Risk of Undetected Defects</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      name='riskOfUndetectedDefects'
                      placeholder='Enter risk of undetected defects'
                      value={formData.riskOfUndetectedDefects}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section C: Safety Hazard Findings */}
              <h5 className='mt-4'>Section D Significant Items</h5>
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                D1: Safety Hazard
              </h5>
              {safetyHazards.map((defect, index) => (
                <React.Fragment key={index}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span></span>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      onClick={() => handleRemoveSafetyHazard(index)}
                    >
                      <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                    </button>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`safetyHazardBuilding-${index}`}>
                        <Form.Label>Building</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter safety hazard building'
                          name='building' // Name should match property in safetyHazards
                          value={defect.building}
                          onChange={(e) => handleSafetyHazardChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`safetyHazardLocation-${index}`}>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter safety hazard location'
                          name='location' // Name should match property in safetyHazards
                          value={defect.location}
                          onChange={(e) => handleSafetyHazardChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                <Col md={6}>
                  <Form.Group controlId={`safetyHazardFinding-${index}`}>
                    <Form.Label>Finding</Form.Label>
                    <Select
                      isMulti
                      options={findingsOptions}
                      onChange={(options) => handleFindingChange(index, options)}
                      value={findingsOptions.filter(option => defect.finding.split(', ').includes(option.value))}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId={`safetyHazardInformation-${index}`}>
                    <Form.Label>Information</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter safety hazard information'
                      name='information'
                      value={defect.information}
                      onChange={(e) => handleSafetyHazardChange(index, e)}
                    />
                  </Form.Group>
                </Col>
              </Row>
                  <Row className='mt-3'>
                    
                    <Col md={6}>
                      <Form.Group controlId={`safetyHazardImages-${index}`}>
                        <Form.Label>Images (Safety Hazards)</Form.Label>
                        <input
                          type='file'
                          multiple
                          onChange={(e) => handleSafetyHazardImageChange(index, e)}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '.375rem .75rem',
                            fontSize: '1rem',
                            lineHeight: '1.5',
                            color: '#495057',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: '1px solid #ced4da',
                            borderRadius: '.25rem',
                            transition:
                              'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                          }}
                        />
                      </Form.Group>
                      </Col>
                      <Col md={6} style={{alignItems:'center', justifyContent:'center', marginTop:'20px'}}>
                      <Button onClick={startCamera} className='btn-secondary'>Open Camera</Button>
              {cameraStream && (
                <>
                  <video ref={videoRef} style={{width: '100%', marginTop: '10px', border: '1px solid black'}} autoPlay
                    playsInline
                    muted/>
                  <canvas ref={canvasRef} style={{display: 'none'}} />
                  <Button onClick={() => takePhoto(index)} className='me-2'>Take Photo</Button>
                  <Button onClick={stopCamera}>Close Camera</Button>
                </>
              )}
              </Col>
                  </Row>
                  <hr className='my-4 mt-8 mb-8 mx-auto' />
                </React.Fragment>
              ))}

              <Button onClick={handleAddSafetyHazard}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Safety
                Hazard
              </Button>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section D: Major Defects */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                D2: Major Defects
              </h5>

              {majorDefects.map((defect, index) => (
                <React.Fragment key={index}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span></span>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      onClick={() => handleRemoveMajorDefect(index)}
                    >
                      <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                    </button>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`majorDefectBuilding-${index}`}>
                        <Form.Label>Building</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter major defect building'
                          name='building' // Name should match property in majorDefects
                          value={defect.building}
                          onChange={(e) => handleMajorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`majorDefectLocation-${index}`}>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter major defect location'
                          name='location' // Name should match property in majorDefects
                          value={defect.location}
                          onChange={(e) => handleMajorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={6}>
                      <Form.Group controlId={`majorDefectFinding-${index}`}>
                        <Form.Label>Finding</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter major defect finding'
                          name='finding' // Name should match property in majorDefects
                          value={defect.finding}
                          onChange={(e) => handleMajorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`majorDefectInformation-${index}`}>
                        <Form.Label>Information</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter major defect information'
                          name='information' // Name should match property in majorDefects
                          value={defect.information}
                          onChange={(e) => handleMajorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={12}>
                      <Form.Group controlId={`majorDefectImages-${index}`}>
                        <Form.Label>Images (Major Defects)</Form.Label>
                        <input
                          type='file'
                          multiple
                          onChange={(e) => handleMajorDefectImageChange(index, e)}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '.375rem .75rem',
                            fontSize: '1rem',
                            lineHeight: '1.5',
                            color: '#495057',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: '1px solid #ced4da',
                            borderRadius: '.25rem',
                            transition:
                              'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className='my-4 mt-8 mb-8 mx-auto' />
                </React.Fragment>
              ))}

              <Button onClick={handleAddMajorDefect}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Major
                Defect
              </Button>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section E: Minor Defects */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                D3: Minor Defects
              </h5>

              {minorDefects.map((defect, index) => (
                <React.Fragment key={index}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span></span>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      onClick={() => handleRemoveMinorDefect(index)}
                    >
                      <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                    </button>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`minorDefectBuilding-${index}`}>
                        <Form.Label>Building</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter minor defect building'
                          name='building' // Name should match property in minorDefects
                          value={defect.building}
                          onChange={(e) => handleMinorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`minorDefectLocation-${index}`}>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter minor defect location'
                          name='location' // Name should match property in minorDefects
                          value={defect.location}
                          onChange={(e) => handleMinorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={6}>
                      <Form.Group controlId={`minorDefectFinding-${index}`}>
                        <Form.Label>Finding</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter minor defect finding'
                          name='finding' // Name should match property in minorDefects
                          value={defect.finding}
                          onChange={(e) => handleMinorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`minorDefectInformation-${index}`}>
                        <Form.Label>Information</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter minor defect information'
                          name='information' // Name should match property in minorDefects
                          value={defect.information}
                          onChange={(e) => handleMinorDefectChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={12}>
                      <Form.Group controlId={`minorDefectImages-${index}`}>
                        <Form.Label>Images (Minor Defects)</Form.Label>
                        <input
                          type='file'
                          multiple
                          onChange={(e) => handleMinorDefectImageChange(index, e)}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '.375rem .75rem',
                            fontSize: '1rem',
                            lineHeight: '1.5',
                            color: '#495057',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: '1px solid #ced4da',
                            borderRadius: '.25rem',
                            transition:
                              'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className='my-4 mt-8 mb-8 mx-auto' />
                </React.Fragment>
              ))}

              <Button onClick={handleAddMinorDefect}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Minor
                Defect
              </Button>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                D4: Further Inspections
              </h5>
              {furtherInspections.map((defect, index) => (
                <React.Fragment key={index}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span></span>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      onClick={() => handleRemoveFurtherInspection(index)}
                    >
                      <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                    </button>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`furtherInspectionBuilding-${index}`}>
                        <Form.Label>Building</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter further inspection building'
                          name='building' // Name should match property in furtherInspections
                          value={defect.building}
                          onChange={(e) => handleFurtherInspectionChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`furtherInspectionLocation-${index}`}>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter further inspection location'
                          name='location' // Name should match property in furtherInspections
                          value={defect.location}
                          onChange={(e) => handleFurtherInspectionChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={6}>
                      <Form.Group controlId={`furtherInspectionFinding-${index}`}>
                        <Form.Label>Finding</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter further inspection finding'
                          name='finding' // Name should match property in furtherInspections
                          value={defect.finding}
                          onChange={(e) => handleFurtherInspectionChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`furtherInspectionInformation-${index}`}>
                        <Form.Label>Information</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter further inspection information'
                          name='information' // Name should match property in furtherInspections
                          value={defect.information}
                          onChange={(e) => handleFurtherInspectionChange(index, e)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={12}>
                      <Form.Group controlId={`furtherInspectionImages-${index}`}>
                        <Form.Label>Images (Further Inspections)</Form.Label>
                        <input
                          type='file'
                          multiple
                          onChange={(e) => handleFurtherInspectionImageChange(index, e)}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '.375rem .75rem',
                            fontSize: '1rem',
                            lineHeight: '1.5',
                            color: '#495057',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: '1px solid #ced4da',
                            borderRadius: '.25rem',
                            transition:
                              'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className='my-4 mt-8 mb-8 mx-auto' />
                </React.Fragment>
              ))}

              <Button onClick={handleAddFurtherInspection}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another
              </Button>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                D5: Conclusion - Assessment of overall condition of property
              </h5>
              <Row>
                <Col md={12}>
                  <Form.Group controlId='recommendations'>
                    <Form.Label>Recommendations</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter Conclusion and overall condition of property'
                      name='recommendations'
                      value={formData.recommendations}
                      onChange={handleChange}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section F: Noted Items */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                D6: Noted Items OR Undefined Defects
              </h5>
              {notedItems.map((defect, index) => (
                <React.Fragment key={index}>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span></span>
                    <button
                      type='button'
                      className='btn btn-sm btn-icon btn-active-color-primary'
                      onClick={() => handleRemoveNotedItem(index)}
                    >
                      <KTSVG path='/media/icons/navigation/close.svg' className='svg-icon-2x' />X
                    </button>
                  </div>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId={`notedItemBuilding-${index}`}>
                        <Form.Label>Building</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter noted item building'
                          name='building'
                          value={defect.building}
                          onChange={handleNotedItemChange(index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`notedItemLocation-${index}`}>
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter noted item location'
                          name='location'
                          value={defect.location}
                          onChange={handleNotedItemChange(index)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={6}>
                      <Form.Group controlId={`notedItemFinding-${index}`}>
                        <Form.Label>Finding</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter noted item finding'
                          name='finding'
                          value={defect.finding}
                          onChange={handleNotedItemChange(index)}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`notedItemInformation-${index}`}>
                        <Form.Label>Information</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter noted item information'
                          name='information'
                          value={defect.information}
                          onChange={handleNotedItemChange(index)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row className='mt-3'>
                    <Col md={12}>
                      <Form.Group controlId={`notedItemImages-${index}`}>
                        <Form.Label>Images (Noted Items)</Form.Label>
                        <input
                          type='file'
                          multiple
                          onChange={(e) => handleNotedItemImageChange(index, e)}
                          style={{
                            display: 'block',
                            width: '100%',
                            padding: '.375rem .75rem',
                            fontSize: '1rem',
                            lineHeight: '1.5',
                            color: '#495057',
                            backgroundColor: '#fff',
                            backgroundClip: 'padding-box',
                            border: '1px solid #ced4da',
                            borderRadius: '.25rem',
                            transition:
                              'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <hr className='my-4 mt-8 mb-8 mx-auto' />
                </React.Fragment>
              ))}

              <Button onClick={handleAddNotedItem}>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg'></KTSVG>Add Another Noted Item
              </Button>

              <hr className='my-4 mt-8 mb-8  mx-auto' />

              {/* Section G: General Recommendations */}
              <h5
                className='mt-4'
                style={{
                  backgroundColor: '#f0f0f0',
                  padding: '8px 12px',
                  borderRadius: '4px',
                  display: 'inline-block',
                  marginRight: '10px',
                }}
              >
                Additional Comments
              </h5>
              <Row>
                <Col md={12}>
                  <Form.Group controlId='additionalComments'>
                    <Form.Label>Recommendations</Form.Label>
                    <Form.Control
                      as='textarea'
                      rows={3}
                      placeholder='Enter general recommendations'
                      name='additionalComments' // Name should match property in state
                      value={formData.additionalComments} // Use formData for consistent state handling
                      onChange={handleChange} // Use a unified change handler
                    />
                  </Form.Group>
                </Col>
              </Row>

              {/* Submit Button */}
              <Row>
                <Col md={4} className='m-auto'>
                  <Button
                    className='mt-5 '
                    variant='primary'
                    type='submit'
                    onClick={() => setShowAddReportModal(false)}
                  >
                    Submit Report
                  </Button>
                </Col>
              </Row>
            </Form>
            </div>
            {/* </Col> */}

            <style>
  {`
    @media print {
      @page {
        margin: 10mm; /* Adjust margins as needed */
      }

      .page-header {
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        text-align: center;
        border-bottom: 1px solid #ddd;
        padding-bottom: 5px;
        font-size: 12px;
        color: #333;
      }

      .page-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        left: 0;
        text-align: center;
        border-top: 1px solid #ddd;
        padding-top: 5px;
        font-size: 12px;
        color: #333;
      }

      .page-number:after {
        content: "Page " counter(page);
      }

      .no-break {
        page-break-inside: avoid;
      }

      .page-break {
        page-break-before: always;
      }

      .page-break-after {
        page-break-after: always;
      }
    }
  `}
</style>


      {/* Preview Section */}
      {/* <Col md={5}> */}
      <div style={{
    flex: 1,
    position: 'sticky',
    top: '0',
    maxHeight: '100%',
    paddingLeft: '20px',

  }}>
      <div className="d-flex justify-content-end">
      <ReactToPrint
            trigger={() => (
  <Button variant="primary" style={{marginBottom: '2px'}}>
    Download PDF
  </Button>
  )}
  content={() => componentRef.current}
  pageStyle="@page { margin: 0; } body { margin: 0; padding: 0; }"
/>
</div>
<div style={{
    flex: 1,
    position: 'sticky',
    maxHeight: '100%',
    overflowY: 'auto',

  }}>

    {/* Header */}
    <div className="page-header">
        A Report Generated By Building Inspection Aus
      </div>

      {/* Footer */}
      <div className="page-footer">
        <span className="page-number"></span>
      </div>
  {/* This is where you will render the form preview */}
   {/* Form Preview Section */}
   <div ref={componentRef} style={reportStyles.receipt}>
            {/* Header Section */}
    <div style={reportStyles.header}>
      <div style={reportStyles.logo}>
        <img src="/media/logos/appicon.png" alt="Logo" style={reportStyles.logoImage} />
      </div>
      <div style={{
      textAlign: 'right',
    }}>
        <h1>{currenteUserName}'s Inspection Service</h1>
        <p>Block 207, Gera</p>
        <p>Hinjewade Phase 2, Pune</p>
      </div>
    </div>

    {/* Basic Information Section */}
    <div className="section no-break" style={{
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    }}>
      <h2 style={reportStyles.sectionTitle}>Basic Information</h2>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <p><strong>Inspection Date:</strong> {formData.inspectionDate?.toLocaleDateString()}</p>
        <p><strong>Property Address:</strong> {formData.propertyAddress}</p>
        <p><strong>City:</strong> {formData.city}</p>
        <p><strong>State:</strong> {formData.state}</p>
        <p><strong>Zipcode:</strong> {formData.zipcode}</p>
      </div>
    </div>

    {/* Parties and Company Information Section */}
    <div className="section no-break" style={{
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    }}>
      <h2 style={reportStyles.sectionTitle}>Parties and Company Information</h2>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <h3>Client Details</h3>
        <p><strong>Client Name:</strong> {formData.clientName}</p>
        <p><strong>Principal Name:</strong> {formData.principalName}</p>
        <p><strong>Job Address:</strong> {formData.jobAddress}</p>
        <p><strong>Client Email:</strong> {formData.clientEmail}</p>
        <p><strong>Client Phone Number:</strong> {formData.clientPhoneNumber}</p>
      </div>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <h3>Company Details</h3>
        <p><strong>Company Name:</strong> {formData.companyName}</p>
        <p><strong>Company Address:</strong> {formData.companyAddress}</p>
        <p><strong>Company Postcode:</strong> {formData.companyPostcode}</p>
        <p><strong>Company Email:</strong> {formData.companyEmail}</p>
        <p><strong>Company Contact Numbers:</strong> {formData.companyContactNumbers}</p>
        <p><strong>Consultant:</strong> {formData.consultant}</p>
      </div>
    </div>

    {/* Inspection Summary Section */}
    <div className="section no-break" style={{
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    }}>
      <h2 style={reportStyles.sectionTitle}>Section A: Results of Inspection - Summary</h2>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <label style={reportStyles.checkbox}><input type="checkbox" checked={!!formData.safetyHazard} readOnly /> Safety Hazard</label>
        <label style={reportStyles.checkbox}><input type="checkbox" checked={!!formData.majorDefect} readOnly /> Major Defect</label>
        <label style={reportStyles.checkbox}><input type="checkbox" checked={!!formData.minorDefect} readOnly /> Minor Defect</label>
        <p><strong>Overall Condition:</strong> {formData.overallCondition}</p>
      </div>
    </div>

    {/* Property Description Section */}
    <div className="section no-break" style={{
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    }}>
      <h2 style={reportStyles.sectionTitle}>Section B: General Description of the Property</h2>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <p><strong>Building Type:</strong> {formData.buildingType + ', '}</p>
        <label style={reportStyles.checkbox}><strong>Company or Strata Title: </strong><input type="checkbox" checked={!!formData.companyOrStrataTitle} readOnly /></label>

        <p><strong>Floor:</strong> {formData.floor}</p>
        <p><strong>Furnished:</strong> {formData.furnished}</p>
        <p><strong>Number of Bedrooms:</strong> {formData.numberOfBedrooms}</p>
        <p><strong>Occupied:</strong> {formData.occupied}</p>
        <p><strong>Orientation:</strong> {formData.orientation}</p>
        <p><strong>Other Building Elements:</strong> {formData.otherBuildingElements}</p>
        <p><strong>Other Timber Building Elements:</strong> {formData.otherTimberBuildingElements}</p>
        <p><strong>Roof:</strong> {formData.roof}</p>
        <p><strong>Storeys:</strong> {formData.storeys}</p>
        <p><strong>Walls:</strong> {formData.walls}</p>
        <p><strong>Weather:</strong> {formData.weather}</p>
      </div>
    </div>

    {/* Accessibility Section */}
    <div className="section no-break" style={{
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    }}>
      <h2 style={reportStyles.sectionTitle}>Section C: Accessibility</h2>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <p><strong>Areas Inspected:</strong> {formData.areasInspected}</p>
        <p><strong>Inaccessible Areas:</strong> {formData.inaccessibleAreas}</p>
        <p><strong>Obstructions and Limitations:</strong> {formData.obstructionsOrLimitations}</p>
        <p><strong>Undetected Defect Risk:</strong> {formData.riskOfUndetectedDefects}</p>
      </div>
    </div>

    {/* Significant Items Section */}
    <div >
      <div className="section no-break" style={{
        marginBottom: '30px',
        pageBreakInside: 'avoid', // Add this to prevent section from breaking
      }}>
  <h2 style={reportStyles.sectionTitle}>Section D: Significant Items</h2>
  </div>
  <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
    {/* Safety Hazards */}
    {safetyHazards.length > 0 && (
      <div className="section no-break" style={{
        marginBottom: '30px',
        pageBreakInside: 'avoid', // Add this to prevent section from breaking
      }}>
        <h3>D1: Safety Hazards</h3>
        {safetyHazards.map((defect, index) => (
          <div key={`safetyHazard-${index}`} style={{
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #eee',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
          }}>
            <p><span style={reportStyles.fieldLabel}>Type:</span> {defect.type}</p>
            <p><span style={reportStyles.fieldLabel}>Building:</span> {defect.building}</p>
            <p><span style={reportStyles.fieldLabel}>Location:</span> {defect.location}</p>
            <p><span style={reportStyles.fieldLabel}>Finding:</span> {defect.finding}</p>
            <p>
  <span style={reportStyles.fieldLabel}>Information:</span> 
  <p>
  <span dangerouslySetInnerHTML={{ __html: defect.information.replace(/\n/g, '<br />') }} />
  </p>
</p>
            {defect.images?.length > 0 && (
              <div style={{
                  maxWidth: '900px',
                  margin: '10px auto',
                  padding: '20px',
                  backgroundColor: '#fff',
                  border: '1px solid #ddd',
                  borderRadius: '10px',
                  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
                }}>
                {defect.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={`Safety Hazard Image ${imgIndex + 1}`} style={{width:'31%', marginRight:'2%', marginBottom:'3%'}} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}

    {/* Major Defects */}
    {majorDefects.length > 0 && (
      <div className="section no-break" style={{
        marginBottom: '30px',
        pageBreakInside: 'avoid', // Add this to prevent section from breaking
      }}>
        <h3>D2: Major Defects</h3>
        {majorDefects.map((defect, index) => (
          <div key={`majorDefect-${index}`} style={{
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #eee',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
          }}>
            <p><span style={reportStyles.fieldLabel}>Type:</span> {defect.type}</p>
            <p><span style={reportStyles.fieldLabel}>Building:</span> {defect.building}</p>
            <p><span style={reportStyles.fieldLabel}>Location:</span> {defect.location}</p>
            <p><span style={reportStyles.fieldLabel}>Finding:</span> {defect.finding}</p>
            <p><span style={reportStyles.fieldLabel}>Information:</span> {defect.information}</p>
            {defect.images?.length > 0 && (
              <div style={{
                maxWidth: '900px',
                margin: '10px auto',
                padding: '20px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '10px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              }}>
                {defect.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={`Major Defect Image ${imgIndex + 1}`} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}

    {/* Minor Defects */}
    {minorDefects.length > 0 && (
      <div className="section no-break" style={{
        marginBottom: '30px',
        pageBreakInside: 'avoid', // Add this to prevent section from breaking
      }}>
        <h3>D3: Minor Defects</h3>
        {minorDefects.map((defect, index) => (
          <div key={`minorDefect-${index}`} style={{
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #eee',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
          }}>
            <p><span style={reportStyles.fieldLabel}>Type:</span> {defect.type}</p>
            <p><span style={reportStyles.fieldLabel}>Building:</span> {defect.building}</p>
            <p><span style={reportStyles.fieldLabel}>Location:</span> {defect.location}</p>
            <p><span style={reportStyles.fieldLabel}>Finding:</span> {defect.finding}</p>
            <p><span style={reportStyles.fieldLabel}>Information:</span> {defect.information}</p>
            {defect.images?.length > 0 && (
              <div style={{
                maxWidth: '900px',
                margin: '10px auto',
                padding: '20px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '10px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              }}>
                {defect.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={`Minor Defect Image ${imgIndex + 1}`} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}

    {/* Further Inspections */}
    {furtherInspections.length > 0 && (
      <div className="section no-break" style={{
        marginBottom: '30px',
        pageBreakInside: 'avoid', // Add this to prevent section from breaking
      }}>
        <h3>D4: Further Inspections</h3>
        {furtherInspections.map((defect, index) => (
          <div key={`furtherInspection-${index}`} style={{
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #eee',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
          }}>
            <p><span style={reportStyles.fieldLabel}>Type:</span> {defect.type}</p>
            <p><span style={reportStyles.fieldLabel}>Building:</span> {defect.building}</p>
            <p><span style={reportStyles.fieldLabel}>Location:</span> {defect.location}</p>
            <p><span style={reportStyles.fieldLabel}>Finding:</span> {defect.finding}</p>
            <p><span style={reportStyles.fieldLabel}>Information:</span> {defect.information}</p>
            {defect.images?.length > 0 && (
              <div style={{
                maxWidth: '900px',
                margin: '10px auto',
                padding: '20px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '10px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              }}>
                {defect.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={`Further Inspection Image ${imgIndex + 1}`} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}

    {/* Comments Field Preview */}
    <h3>D5: Additional Comments: </h3>
    {formData.recommendations && (
      <div style={{
        marginBottom: '15px',
        padding: '10px',
        border: '1px solid #eee',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
      }}>
        <p>{formData.recommendations}</p>
      </div>
    )}

    {/* Noted Items */}
    {notedItems.length > 0 && (
      <div className="section no-break" style={{
        marginBottom: '30px',
        pageBreakInside: 'avoid', // Add this to prevent section from breaking
      }}>
        <h3>D6: Noted Items</h3>
        {notedItems.map((defect, index) => (
          <div key={`notedItem-${index}`} style={{
            marginBottom: '15px',
            padding: '10px',
            border: '1px solid #eee',
            borderRadius: '5px',
            backgroundColor: '#f9f9f9',
            pageBreakInside: 'avoid', // Add this to prevent defect items from breaking
          }}>
            <p><span style={reportStyles.fieldLabel}>Type:</span> {defect.type}</p>
            <p><span style={reportStyles.fieldLabel}>Building:</span> {defect.building}</p>
            <p><span style={reportStyles.fieldLabel}>Location:</span> {defect.location}</p>
            <p><span style={reportStyles.fieldLabel}>Finding:</span> {defect.finding}</p>
            <p><span style={reportStyles.fieldLabel}>Information:</span> {defect.information}</p>
            {defect.images?.length > 0 && (
              <div style={{
                maxWidth: '900px',
                margin: '10px auto',
                padding: '20px',
                backgroundColor: '#fff',
                border: '1px solid #ddd',
                borderRadius: '10px',
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.2)',
              }}>
                {defect.images.map((image, imgIndex) => (
                  <img key={imgIndex} src={image} alt={`Noted Item Image ${imgIndex + 1}`} />
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    )}

    {/* Fallback for no defects */}
    {safetyHazards.length === 0 &&
    majorDefects.length === 0 &&
    minorDefects.length === 0 &&
    furtherInspections.length === 0 &&
    notedItems.length === 0 && (
      <p>No defects available.</p>
    )}
  </div>
</div>

    </div>

    {/* Additional Comments Section */}
    <div className="section no-break" style={{
      marginBottom: '30px',
      pageBreakInside: 'avoid', // Add this to prevent section from breaking
    }}>
      <h2 style={reportStyles.sectionTitle}>Additional Comments</h2>
      <div style={{
      padding: '20px',
      border: '1px solid #ddd',
      borderRadius: '8px',
      backgroundColor: '#fafafa',
      marginTop: '10px',
      pageBreakInside: 'avoid', // Add this to prevent details from breaking
    }}>
        <p><strong>Recommendations:</strong> {formData.additionalComments}</p>
      </div>
    </div>

    <div style={{
      textAlign: 'center',
      fontSize: '14px',
      color: '#777',
      marginTop: '30px',
      borderTop: '1px solid #ddd',
      paddingTop: '15px',
    }}>
      &copy; 2024 Building Inspection Aus. All rights reserved.
    </div>
  </div>
</div>
      {/* </Col> */}
      {/* </Row> */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  )
}

export {InspectionsReports}
