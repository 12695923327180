/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { Dropdown1 } from '../../../../_metronic/partials'
import { useLocation } from 'react-router'
import { useAuth } from '../../auth'

const ProfileHeader: React.FC = () => {
    const location = useLocation()
    const auth = useAuth();

    const roleMapping: { [key: number]: string } = {
        1: 'Admin',
        2: 'Inspector',
        3: 'Seller',
        4: 'Buyer'
    };

    // Get the role string based on the role number
    const role = auth.currentUser?.role;
    const roleString = role !== undefined ? roleMapping[role] : 'Unknown';
    
    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    {/* <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={toAbsoluteUrl('/media/avatars/300-1.jpg')} alt='Metronic' />
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div> */}

                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                        {auth.currentUser?.firstname} {auth.currentUser?.lastname}
                                    </a>
                                    <a href='#'>
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen026.svg'
                                            className='svg-icon-1 svg-icon-primary'
                                        />
                                    </a>
                                
                                </div>

                                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                                    {/* <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com006.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        
                                        {auth.currentUser?.role}
                                    </a> */}
  <a
                            href='#'
                            className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                        >
                            <KTSVG
                                path='/media/icons/duotune/communication/com006.svg'
                                className='svg-icon-4 me-1'
                            />
                            {roleString}
                        </a>


                                    
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen018.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {auth.currentUser?.phone_no}
                                    </a>
                                    <a
                                        href='#'
                                        className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/communication/com011.svg'
                                            className='svg-icon-4 me-1'
                                        />
                                        {auth.currentUser?.email}
                                    </a>
                                </div>
                            </div>

                           
                        </div>

                 
                    </div>
                </div>

                <div className='d-flex overflow-auto h-55px'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/crafted/account/overview' && 'active')
                                }
                                to='/user/profile/overview'
                            >
                                Overview
                            </Link>
                        </li>
            
                    </ul>
                </div>
            </div>
        </div>
    )
}

export { ProfileHeader }
